import Vue from 'vue';
import VueFormulate from '@braid/vue-formulate'
import App from './App.vue';
import { router } from './router';
import store from './store';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import VeeValidate from 'vee-validate';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faHome,
  faUser,
  faCheck,
  faTimesCircle,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt,
  faMinus
} from '@fortawesome/free-solid-svg-icons';

import PortalVue from 'portal-vue'

import FormulateVSelectPlugin from '@cone2875/vue-formulate-select'

import 'vue-select/dist/vue-select.css';

import JsonCSV from 'vue-json-csv'
Vue.component('downloadCsv', JsonCSV)

import VueGoodTablePlugin from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css'

Vue.use(VueGoodTablePlugin);


import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'


// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)




import axios from 'axios'

const axiosInstance = axios.create({
  baseURL: 'https://validations.indivillage.com/api/'  
  //baseURL: 'http://southvalidationapp-env.eba-jmaq2kez.ap-south-1.elasticbeanstalk.com/api/'  
  //baseURL: "http://localhost:8080/api/"
})


Vue.use(VueFormulate, {
  uploader: axiosInstance,
  uploadUrl: '/fileupload',
  plugins: [ FormulateVSelectPlugin ]
})


library.add(faHome, faUser, faUserPlus, faCheck, faTimesCircle, faSignInAlt, faSignOutAlt, faMinus);

Vue.use(PortalVue)

Vue.config.productionTip = false;

Vue.use(VueFormulate)

Vue.use(VeeValidate, {
  errorBagName: 'vErrors',
  fieldsBagName: 'vFields'
});

Vue.component('font-awesome-icon', FontAwesomeIcon);


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
