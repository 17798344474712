<template>
  <div class="container">
    <div class="card card-container">
      <FormulateForm
        @submit="submitHandler"
        v-model="values"
        :schema="schema"
      />
    </div>
  </div>
</template>

<script>
import ProjectService from "../services/upload.service";

export default {
  name: "UploadItem",

  created() {
    document.title = "ADMIN || INDIVILLAGE VALIDATION PLATFORM";
  },

  data() {
    return {
      values: {},
      schema: [
        {
          component: "h3",
          children: "PLU List Upload",
        },
        {
          type: "file",
          name: "file",
          label: "Select your documents to upload",
          validation: "mime:text/csv",
        },
        {
          type: "submit",
          label: "Upload a file",
        },
      ],
    };
  },

  methods: {
    submitHandler(data) {
      ProjectService.createPLU(data).then(
        (response) => {
          var link_to_push = "/admin";
          this.$router.push(link_to_push);
          return response;
        },
        (error) => {
          alert(error.response.data.message);
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
};
</script>

<style scoped>
@import "../assets/scss/snow.scss";

.card-container.card {
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

div.flex-wrapper {
  display: flex !important;
}

/* Media Query for Mobile Devices */
@media (max-width: 480px) {
}
</style>
