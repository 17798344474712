<template>
    <div  class="container">
      <div class="card" style="width: 100%">
            <b-tabs content-class="mt-3">
                <b-tab title="Errors" active>
              <table class="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Error Type</th>
                        <th scope="col">Comments</th>
                        <th scope="col">QC User</th>
                        <th scope="col">Users who made error</th>
                        <th scope="col">Finished Date (UTC)</th>
                        <th scope="col">Finished Time (UTC)</th>
                        <th scope="col">Link</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="qctask in errors[0]" :key="qctask._id">
                        <td>{{ qctask.error_type }}</td>
                        <td>{{ qctask.comments }}</td>
                        <td>{{ qctask.owners.email }}</td>
                        <td v-if="qctask.error_users"> <span v-for="users in qctask.error_users" :key="users._id">{{ users }} </span> </td>
                        <td v-else> </td>

                        <td>{{ qctask.finished_date.substring(0, 10) }}</td>
                        <td>{{ qctask.finished_date.substring(11, 16) }}</td>
                        <td>
                          <router-link
                            :to="'../recorddrill/' + qctask.record"
                            class="custom-link"
                          >
                            Details
                          </router-link>
                        </td>
                      </tr>
                    </tbody>
                  </table>        
      
                </b-tab>
                <b-tab title="Inconsitencies">
                <table class="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Error Type</th>
                        <th scope="col">Comments</th>
                        <th scope="col">QC User</th>
                        <th scope="col">Users who made error</th>
                        <th scope="col">Finished Date (UTC)</th>
                        <th scope="col">Finished Time (UTC)</th>
                        <th scope="col">Link</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="qctaski in errors[1]" :key="qctaski._id">
                        <td>{{ qctaski.error_type }}</td>
                        <td>{{ qctaski.comments }}</td>
                        <td>{{ qctaski.owners.email }}</td>
                        <td v-if="qctaski.error_users"> <span v-for="users in qctaski.error_users" :key="users._id">{{ users }} </span></td>
                        <td v-else> </td>

                        <td>{{ qctaski.finished_date.substring(0, 10) }}</td>
                        <td>{{ qctaski.finished_date.substring(11, 16) }}</td>
                        <td>
                          <router-link
                            :to="'../recorddrill/' + qctaski.record"
                            class="custom-link"
                          >
                            Details
                          </router-link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-tab>
              </b-tabs>
        </div>
    </div>
</template>

<script>
import UserService from "../services/user.service";

export default {
  name: "UserItem",

  data() {
    return {
      errors: [],
    };
  },
  
    created() {
      document.title = "DOCUMENT REVIEW || INDIVILLAGE VALIDATION PLATFORM";
      UserService.uploadErrors(this.$route.params.id).then(
      (response) => {
        this.errors = response.data;
      },
      (error) => {
        this.content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
      }
    );
  },
    }
</script>

<style scoped>
@import "../assets/scss/snow.scss";

.card-container.card {
  padding: 40px 40px;
}

.card {
  width: 100%;
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

div.flex-wrapper {
  display: flex !important;
}

.custom-link {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.3em;
  box-sizing: border-box;
  background-color: transparent;
  font-size: 0.9em;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  padding: 0.75em;
  display: block;
  width: 100%;
  font-weight: 400;
  line-height: 1.2em;
  margin: 0;
  border: 1px solid #41b883;
  background-color: #41b883;
  color: #fff;
  min-width: 0;
  width: auto;
  font-weight: 700;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
}

/* Media Query for Mobile Devices */
@media (max-width: 480px) {
  .card {
    width: 150%;
  }
}
</style>
