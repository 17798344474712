import axios from 'axios';
import authHeader from './auth-header';

import {URL} from '../models/constants';

const API_URL = URL+'user/';

class UserService {
  getPublicContent() {
    return axios.get(API_URL + 'all');
  }

  getUserOptions() {
    return axios.get(API_URL + 'options', { headers: authHeader() });
  }

  getTask(data) {
    return axios.get(API_URL + 'task/new/'+data, { headers: authHeader() });
  }

  getTaskNL(data) {
    return axios.get(API_URL + 'tasknl/new/'+data, { headers: authHeader() });
  }

  getTaskMulti(data) {
    return axios.get(API_URL + 'taskmulti/new/'+data, { headers: authHeader() });
  }

  getCertTask() {
    return axios.get(API_URL + 'Certtask/new', { headers: authHeader() });
  }

  releaseTask(data) {
    return axios.get(API_URL + 'task/release/'+data, { headers: authHeader() });
  }


  completeTask(data) {
    return axios.post(API_URL + 'task/complete',data, { headers: authHeader() });
  }
  

  completeTaskMulti(data) {
    return axios.post(API_URL + 'taskmulti/complete',data, { headers: authHeader() });
  }


  completeCertTask(data) {
    return axios.post(API_URL + 'Certtask/complete',data, { headers: authHeader() });
  }


  rejectTask(data) {
    return axios.get(API_URL + 'task/reject/'+data, { headers: authHeader() });
  }

  passToExpertTask(data) {
    return axios.get(API_URL + 'task/toexpert/'+data, { headers: authHeader() });
  }

  passToExpertTaskMulti(data) {
    return axios.get(API_URL + 'taskmulti/toexpert/'+data, { headers: authHeader() });
  }

  searchItemName(data) {
    return axios.post(URL + 'search/itemname', data, { headers: authHeader() });
  }

  searchPLU(data) {
    return axios.post(URL + 'search/plu', data, { headers: authHeader() });
  }

  searchAlcohol(data) {
    return axios.post(URL + 'search/alcohol', data, { headers: authHeader() });
  }

  searchPLUStock(data) {
    return axios.post(URL + 'search/plustock', data, { headers: authHeader() });
  }

  searchBrand(data) {
    return axios.post(URL + 'search/brand', data, { headers: authHeader() });
  }

  searchCategory(data) {
    return axios.post(URL + 'search/category', data, { headers: authHeader() });
  }


  searchBrandCH(data) {
    return axios.post(URL + 'search/brandch', data, { headers: authHeader() });
  }

  testTask(data) {
    return axios.get(API_URL + 'task/test/'+data, { headers: authHeader() });
  }

  getQCTask(data) {
    return axios.get(API_URL + 'qctask/'+data, { headers: authHeader() });
  }

  getQCTaskMulti(data) {
    return axios.get(API_URL + 'qctaskmulti/'+data, { headers: authHeader() });
  }

  reloadQCTask(data) {
    return axios.get(API_URL + 'reloadqctask/'+data, { headers: authHeader() });
  }

  reloadQCTaskMulti(data) {
    return axios.get(API_URL + 'reloadqctaskmulti/'+data, { headers: authHeader() });
  }

  submitQCTask(data) {
    return axios.post(API_URL + 'qctask', data, { headers: authHeader() });
  }

  submitQCTaskMulti(data) {
    return axios.post(API_URL + 'qctaskmulti', data, { headers: authHeader() });
  }


  onholdQCTask(data) {
    return axios.post(API_URL + 'qchold', data, { headers: authHeader() });
  }

  getAllQCTasks() {
    return axios.get(API_URL + 'allqctasks', { headers: authHeader() });
  }

  getTakenQCTasks() {
    return axios.get(API_URL + 'takenqctasks', { headers: authHeader() });
  }

  drillDownRecord(data) {
    return axios.get(API_URL + 'recorddrill/'+data, { headers: authHeader() });
  }

  allErrors() {
    return axios.get(API_URL + 'allErrors', { headers: authHeader() });
  }
  
  allInconsistent() {
    return axios.get(API_URL + 'allInconsistent', { headers: authHeader() });
  }


  uploadErrors(data) {
    return axios.get(API_URL + 'uploadErrors/'+data, { headers: authHeader() });
  }

  accept(data) {
    return axios.post(API_URL + 'acceptError/'+data[0],data[1], { headers: authHeader() });
  }

  reject(data) {
    return axios.post(API_URL + 'rejectError/'+data[0],data[1], { headers: authHeader() });
  }

  acceptQC(data) {
    return axios.post(API_URL + 'acceptErrorQC/'+data[0],data[1], { headers: authHeader() });
  }

  rejectQC(data) {
    return axios.post(API_URL + 'rejectErrorQC/'+data[0],data[1], { headers: authHeader() });
  }
  
  loadperformance() {
    return axios.get(API_URL + 'loadperformance', { headers: authHeader() });
  }

}

export default new UserService();
