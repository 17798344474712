<template>
  <div class="container">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Project: {{ schema[0].name }}</h5>
        <h6 class="card-subtitle mb-2 text-muted">Uploads:</h6>
        <!-- <div class="row">
          <div class="search-wrapper panel-heading col-sm-12">
            <input
              class="form-control"
              type="text"
              v-model="search"
              placeholder="Search"
            />
            
          </div>
        </div> -->
        <ProjectSearch />
        <table v-if="schema[0].name.includes('Certification')" class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Upload Name</th>
              <th scope="col">Upload Date</th>

              <th scope="col">Records</th>
              <th scope="col">Tasks</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in schema[3]" :key="item">
              <td>{{ item.name }}</td>
              <td>{{ item.created_date.substring(0, 10) }}</td>

              <td>{{ item.certrecords[0]['count'] }}</td>
              <td>{{ item.certtasks[0]['count'] }}</td>


            </tr>
          </tbody>
        </table>
        <table v-else class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Upload Name</th>
              <th scope="col">Upload Date</th>
              <th scope="col">SLA Date</th>
              <th scope="col">SLA Time (UTC)</th>
              <th scope="col">Billable</th>
              <th scope="col">Not Started</th>
              <th scope="col">In L1</th>
              <th scope="col">In L2</th>
              <th scope="col">In L3</th>
              <th scope="col">In QC</th>
              <th scope="col">Finished</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in schema[3]" :key="item">
              <td>{{ item.name }}</td>
              <td>{{ item.created_date.substring(0, 10) }}</td>
              <td>{{ item.SLA_date.substring(0, 10) }}</td>
              <td>{{ item.SLA_date.substring(11, 16) }}</td>
              <td>
                <font-awesome-icon icon="check" v-if="item.billable" />
                <font-awesome-icon icon="times-circle" v-else />
              </td>
              <td>{{ item.new_records["Not started"] }}</td>
              <td>{{ item.new_records["In L1"] }}</td>
              <td>{{ item.new_records["In L2"] }}</td>
              <td>{{ item.new_records["In L3"] }}</td>
              <td
                v-if="
                  item.new_records['In QC'] && item.new_records['Finished QC']
                "
              >
                {{
                  Number(item.new_records["In QC"]) +
                  Number(item.new_records["Finished QC"])
                }}
              </td>
              <td v-else-if="item.new_records['In QC']">
                {{ Number(item.new_records["In QC"]) }}
              </td>
              <td v-else-if="item.new_records['Finished QC']">
                {{ Number(item.new_records["Finished QC"]) }}
              </td>
              <td v-else></td>
              <td>{{ item.new_records["Finished"] }}</td>
              <td>

                <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle custom-link" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      Upload Actions
                    </button>
                    <ul class="dropdown-menu">

                      <li>
                        <router-link :to="'../UploadSummary/' + item._id" class="dropdown-item" tag="button">
                          Upload Overview
                        </router-link>
                      </li>
                      <li>
                        <button 
                        class="dropdown-item"
                          type="button"
                          @click="generateCsv(item._id)"
                        >
                          Generate CSV
                        </button>
                      </li>
                      <li v-if="item.integration_source=='WF_FULL'">
                        <router-link 
                          :to="'../upload_final_qc'"
                          class="dropdown-item"
                        >
                          Uplaod Final QC File
                        </router-link>
                      </li>
                      <li v-if="otherLink">
                        <router-link 
                          :to="'/inProd/edit/' + item._id"
                          class="dropdown-item"
                        >
                          Edit Project
                        </router-link>
                      </li>
                      <li v-if="item.integration_source=='WF_FULL'">
                        <router-link 
                          :to="'../export/review/'+item._id"
                          class="dropdown-item"
                        >
                          Submit
                        </router-link>
                      </li>
                      <li v-else>
                        <button
                        class="dropdown-item"
                          type="button"
                          @click="hideUpload(item._id)"
                        >
                          Mark as completed
                        </button>
                      </li>

                    </ul>
                  </div>




              </td>
              <td>


              </td>
              <td>




              </td>
            </tr>
          </tbody>
        </table>

        <router-link v-if="schema[0].name.includes('Certification')" :to="'/certupload/' + schema[0]._id" class="custom-link">
          New Certification Upload
        </router-link>
        <router-link v-else :to="'/upload/' + schema[0]._id" class="custom-link">
          New Upload
        </router-link>

        <router-link
          :to="'/editproject/' + schema[0]._id"
          class="custom-link margin-left"
        >
          Edit Project
        </router-link>

        <download-csv
          v-if="json_data.length > 0"
          class="custom-link margin-left"
          :data="json_data"
        >
          Download Data
        </download-csv>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Fields Used:</h5>
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Field Name</th>
              <th scope="col">Field Rank</th>
              <th scope="col">Used in Upload</th>
              <th scope="col">Used in Download</th>
              <th scope="col">Visible in Stages</th>
              <th scope="col">Editable in Stages</th>
              <th scope="col">Mandatory in Stages</th>
              <th scope="col">Image?</th>
              <th scope="col">Link?</th>
              <th scope="col">Validated?</th>
              <th scope="col">Looked-up?</th>
              <th scope="col">Edit</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in schema[2]" :key="item.name">
              <td>{{ item.values.name }}</td>
              <td>{{ item.values.rank }}</td>
              <td>
                <font-awesome-icon icon="check" v-if="item.values.upload" />
                <font-awesome-icon icon="times-circle" v-else />
              </td>
              <td>
                <font-awesome-icon icon="check" v-if="item.values.download" />
                <font-awesome-icon icon="times-circle" v-else />
              </td>
              <td>
                <span v-for="vis in item.vis" :key="vis.name">{{ vis }}, </span>
              </td>
              <td>
                <span v-for="edit in item.edit" :key="edit.name"
                  >{{ edit }},
                </span>
              </td>
              <td>
                <span v-for="man in item.man" :key="man.name">{{ man }}, </span>
              </td>
              <td>
                <font-awesome-icon icon="check" v-if="item.values.image" />
                <font-awesome-icon icon="times-circle" v-else />
              </td>
              <td>
                <font-awesome-icon icon="check" v-if="item.values.link" />
                <font-awesome-icon icon="times-circle" v-else />
              </td>
              <td>
                <font-awesome-icon
                  icon="check"
                  v-if="
                    item.values.validation_reference != null &&
                    item.values.validated
                  "
                />
                <font-awesome-icon
                  icon="minus"
                  v-else-if="item.values.validated"
                />
                <font-awesome-icon icon="times-circle" v-else />
              </td>
              <td>
                <font-awesome-icon
                  icon="check"
                  v-if="
                    item.values.lookup_reference != null &&
                    item.values.looked_up
                  "
                />
                <font-awesome-icon
                  icon="minus"
                  v-else-if="item.values.looked_up"
                />
                <font-awesome-icon icon="times-circle" v-else />
              </td>
              <td>
                <router-link
                  :to="'/editprojectfield/' + item.values._id"
                  class="custom-link"
                  >Edit</router-link
                >
              </td>
            </tr>
          </tbody>
        </table>

        <router-link
          :to="'/newprojectfield/' + schema[0]._id"
          class="custom-link"
        >
          Add a field
        </router-link>

        <router-link
          :to="'/upvalidation/' + schema[0]._id"
          class="custom-link margin-left"
        >
          Add a validation
        </router-link>

        <router-link
          :to="'/uplookup/' + schema[0]._id"
          class="custom-link margin-left"
        >
          Add a look-up
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectService from "../services/project.service";
import ProjectSearch from "@/components/ProjectSearch.vue";

export default {
  name: "NewProjectUpdate",

  components: { ProjectSearch },

  created() {
    document.title = "ADMIN || INDIVILLAGE VALIDATION PLATFORM";
    ProjectService.details(this.$route.params.id).then(
      (response) => {
        this.schema = response.data;
        console.log(response, "Project Details")
      },
      (error) => {
        this.content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
      }
    );
    ProjectService.getAllProjects().then(
      (response) => {
        this.schema = response.data;
        console.log(response, "getAllProjects");
      },
      (error) => {
        this.content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
      }
    );
  },

  data() {
    return {
      values: {},
      schema: [],
      json_data: [],
      search: "",
    };
  },

  methods: {
    submitHandler(data) {
      ProjectService.edit([this.$route.params.id, data]).then(
        (response) => {
          alert("Success!");
          return response;
        },
        (error) => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },
    generateCsv(data) {
      ProjectService.generateDownload(data).then(
        (response) => {
          this.json_data = response.data;
          alert("CSV GENERATED!");
          return response;
        },
        (error) => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },
    hideUpload(data) {
      ProjectService.hideUpload(data).then(
        (response) => {
          this.json_data = response.data;
          alert("Acknowledged!");
          return response;
        },
        (error) => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },

    containsKey(obj, key) {
      return Object.keys(obj).includes(key);
    },
  },
  computed: {
    hasValidation() {
      return this.containsKey(this.someObject, "validation_reference");
    },
    hasLookup() {
      return this.containsKey(this.someObject, "lookup_reference");
    },
    currentUser() {
      return this.$store.state.auth.user;
    },

    otherLink() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes("ROLE_PROD_HEAD");
      }
      return false;
    }
  },
};
</script>

<style scoped>
@import "../assets/scss/snow.scss";

.card-container.card {
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  display: inline-block !important;
}

div.flex-wrapper {
  display: flex !important;
}

.organe {
  border: 1px solid #b8a541 !important;
  background-color: #b8a541 !important;
}

.custom-link {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.3em;
  box-sizing: border-box;
  background-color: transparent;
  font-size: 0.9em;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  padding: 0.75em;
  display: block;
  width: 100%;
  font-weight: 400;
  line-height: 1.2em;
  margin: 0;
  border: 1px solid #41b883;
  background-color: #41b883;
  color: #fff;
  min-width: 0;
  width: auto;
  font-weight: 700;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
}

.margin-left {
  margin-left: 80px !important;
}
</style>
