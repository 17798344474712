<template>
  <div class="container">
    <div class="card card-container">
      <FormulateForm
        @submit="submitHandler"
        v-model="values"
        :schema="schema"
      />
    </div>
  </div>
</template>

<script>
import ProjectService from "../services/upload.service";

export default {
  name: "UploadItem",

  created() {
    document.title = "ADMIN || INDIVILLAGE VALIDATION PLATFORM";
    ProjectService.loadNewLookup(this.$route.params.projectid).then(
      (response) => {
        this.schema = response.data;
      },
      (error) => {
        this.content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
      }
    );
  },

  data() {
    return {
      values: {},
      schema: [],
    };
  },
    /* Here the API is throwing error 500. No data is fetching from server side */
  methods: {
    submitHandler(data) {
      ProjectService.createNewLookup([this.$route.params.projectid, data]).then(
        (response) => {
          var link_to_push = "/project/" + this.$route.params.projectid;
          this.$router.push(link_to_push);
          return response;
        },
        (error) => {
          alert(error.message);
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
};
</script>

<style scoped>
@import "../assets/scss/snow.scss";

.card-container.card {
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

div.flex-wrapper {
  display: flex !important;
}

/* Media Query for Mobile Devices */
@media (max-width: 480px) {
}
</style>
