
<template>
  <div class="container">

    <FormulateForm
      v-model="values"
      @submit="submitHandler"
    >

    <!-- <FormulateInput type='hidden' name='taskid' :value=taskid /> -->

    <div
      v-for="question in schema"
      :key="question.name"
      class="card"
      style="width: 100%"
    >
      <div class="card-body">
        <h3 class="card-title">{{ question.title }}</h3>


        <div
              v-for="field in question.fields"
              :key="field.name"
              style="display: grid"
            >
            <h5>
            <span v-if="field.type==='image'"><img :src="field.value" class="img-thumbnail validate-image" style="background-color: #FFFFFF" /></span>
           <span v-else-if="field.type==='text'">{{field.name}}:<b>{{field.value}}</b></span></h5>
              
            </div>

            <br>

            <h5 v-for="ans_f in question.answer"
              :key="ans_f.name"
            >


            <FormulateInput v-if="ans_f.type==='radio'" :element-class="['input-class-add']" :type=ans_f.type :help=ans_f.instructions :name=ans_f.name :label=ans_f.label :options=ans_f.values validation="required" />
            <FormulateInput v-else :element-class="['input-class-add']" :type=ans_f.type :help=ans_f.instructions :name=ans_f.name :label=ans_f.label value=""/>

            <div v-if="ans_f.showBrand">
              <span class = "description_add">
                Brand Search Box
              </span>
            <input type="text" name="search-box" @keyup="searchBrand" />
            <template
              v-if="!state.noBrandSearch && state.searchResultsBrand.length > 0"
            >
              <div
                class="search-item"
                v-for="(Brand, Brandindex) in state.searchResultsBrand"
                v-bind:key="Brandindex"
              >
              <a
                  href="#"
                  v-on:click.prevent="suggestionInsert([Brand.name,ans_f.name])"
                  class="search-results"
                >

                  {{ Brand.name }}</a>
              </div>
            </template>
            <template v-else-if="!state.noBrandSearch">
              <div class="search-item">
                <p>Sorry. No Results.</p>
              </div>
            </template>
          </div>

          <div v-if="ans_f.showCategory">
            <span class = "description_add">
                Category Search Box
              </span>
            <input type="text" name="search-box" @keyup="searchCategory" />
            <template
              v-if="!state.noCategorySearch && state.searchResultsCategory.length > 0"
            >
              <div
                class="search-item"
                v-for="(Category, Categoryindex) in state.searchResultsCategory"
                v-bind:key="Categoryindex"
              >
                <hr v-if="Categoryindex > 0" />

                <a
                  href="#"
                  v-on:click.prevent="suggestionInsert([Category.name,ans_f.name])"
                  class="search-results"
                >
                {{ Category.name }}</a>

              </div>
            </template>
            <template v-else-if="!state.noCategorySearch">
              <div class="search-item">
                <p>Sorry. No Results.</p>
              </div>
            </template>
          </div>
          
          <br>
          
          </h5>
          





      </div>
    </div>

    <div class="card" style="width: 100%"><div class="card-body">
      <FormulateInput     type="submit" name="Submit answers" />

      <button
              class="custom-link"
              type="button"
              @click="passToExpert"
            >
              Pass To Expert
            </button>
    </div></div>


  </FormulateForm>
  </div>
</template>







<script>
import UserService from "../services/user.service";
import { reactive } from "vue";

export default {
  name: "UserItem",
  setup() {
    const state = reactive({
      searchResultsBrand: [],
      searchResultsCategory: [],
      noBrandSearch: true,
      noCategorySearch: true
    });
    function searchBrand(e) {
      let searchBrand = e.target.value.trim();
      UserService.searchBrand({ search: searchBrand })
        .then((res) => {
          if (searchBrand === "") {
            state.noBrandSearch = true;
            return;
          }
          state.noBrandSearch = false;
          state.searchResultsBrand = res.data;
        })
        .catch((err) => {
          throw new Error(err);
        });
    }
    function searchCategory(e) {
      let searchCategory = e.target.value.trim();
      UserService.searchCategory({ search: searchCategory })
        .then((res) => {
          if (searchCategory === "") {
            state.noCategorySearch = true;
            return;
          }
          state.noCategorySearch = false;
          state.searchResultsCategory = res.data;
        })
        .catch((err) => {
          throw new Error(err);
        });
    }



    return { searchBrand, searchCategory, state };
  },

  created() {
    document.title = "VALIDATION || INDIVILLAGE VALIDATION PLATFORM";
  UserService.getTaskNL(this.$route.params.id).then(
      (response) => {
        this.schema = response.data[0];
        this.taskid = response.data[1]
      },
      (error) => {
        alert(error.response.data.message);
        this.$router.push("/user");
        this.content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
      }
    ); 
    this.countDownTimer();
  },

  data() {
    return {
      values: {},
      schema: [],
      taskid: '',
      countDown: 999999,
    };
  },
  methods: {
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      } else {
        this.$router.push("/user");
      }
    },
    submitHandler(data) {
      var new_data = data
      new_data['taskid'] = this.taskid
      UserService.completeTask(new_data).then(
        (response) => {
          this.$router.go();
          return response;
        },
        (error) => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },
    rejectTask() {
      UserService.rejectTask(this.schema[1].value).then(
        (response) => {
          this.$router.go();
          return response;
        },
        (error) => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },
    passToExpert() {
      UserService.passToExpertTask(this.taskid).then(
        (response) => {
          this.$router.go();
          return response;
        },
        (error) => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },
    suggestionInsert(i) {

      this.values[i[1]] = i[0];

      console.log(this.values[i[1]])

    },
    
  },
};
</script>

<style>
@import "../assets/scss/snow.scss";


.description_add{
  color: #6d6d6d;
    font-size: .7em;
    margin-bottom: .25em;
    font-weight: bold;
}

.input-class-add {
  max-width: 100% !important;
}

.formulate-input-element--radio {
  overflow: visible !important;
}


.search-results {
  font-size: 15px;
  color: #41b883;
}

.card-container.card {
  padding: 40px 40px;
}

.additional {
  max-width: 50%;
}


.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

div.flex-wrapper {
  display: flex !important;
}

.custom-link {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.3em;
  box-sizing: border-box;
  background-color: transparent;
  font-size: 0.9em;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  padding: 0.75em;
  display: block;
  font-weight: 400;
  line-height: 1.2em;
  margin: 0;
  border: 1px solid #964038;
  background-color: #964038;
  color: #fff;
  min-width: 0;
  width: auto;
  font-weight: 700;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
}

.margin-left {
  margin-left: 80px !important;
}

input[name="search-box"] {
  display: block;
  margin: 2rem auto;
  width: 95%;
  padding: 0.5rem 1rem;
}
.search-item {
  margin: 2rem auto;
  width: 95%;
  font-weight: 500;
  font-size: 1rem;
}
.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}


</style>
