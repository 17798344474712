<template>
  <div class="container">
    <div class="row">
      <div class="col-sm">
        <div class="card card-container">
          <img :src="imported_images[0]" class="img-thumbnail" />
          <div v-for="item in imported_data" :key="item.id">
            {{ item[0] }} :

            <span v-if="links.includes(item[1])">
              <a :href="item[1]" target="_blank">{{ item[1] }}</a></span
            >
            <span v-else>
              <b>{{ item[1] }}</b></span
            >
          </div>
          <div v-if="show">
            <br /><br />
            <h5>Search databased for title</h5>
            <input type="text" name="search-box" @keyup="search" />
            <template v-if="!state.noSearch && state.searchResults.length > 0">
              <div
                class="search-item"
                v-for="(result, index) in state.searchResults"
                :key="index"
              >
                <hr v-if="index > 0" />
                <p>{{ result }}</p>
              </div>
            </template>
            <template v-else-if="!state.noSearch">
              <div class="search-item">
                <p>Sorry. No Results.</p>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="col-sm">
        <div class="card card-container">
          <FormulateForm
            @submit="submitHandler"
            v-model="values"
            :schema="schema"
          />
          <h5>Other Actions</h5>
          <div>
            <button class="custom-link" type="button" @click="rejectTask">
              Reject Task
            </button>
            <button
              class="custom-link margin-left"
              type="button"
              @click="passToExpert"
            >
              Pass To Expert
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "../services/user.service";
import { reactive } from "vue";

export default {
  name: "UserItemTest",
  setup() {
    const state = reactive({
      searchResults: [],
      noSearch: true,
    });
    function search(e) {
      let search = e.target.value.trim();
      UserService.searchItemName({ search: search })
        .then((res) => {
          if (search === "") {
            state.noSearch = true;
            return;
          }
          state.noSearch = false;
          state.searchResults = res.data.map((title) => title.name);
        })
        .catch((err) => {
          throw new Error(err);
        });
    }
    return { search, state };
  },

  created() {
    document.title = "VALIDATION || INDIVILLAGE VALIDATION PLATFORM";
    UserService.testTask(this.$route.params.id).then(
      (response) => {
        this.imported_images = response.data[0];
        this.imported_data = response.data[1];
        this.schema = response.data[2];
        this.show = response.data[3];
        this.links = response.data[4];
      },
      (error) => {
        this.content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
      }
    );
  },

  data() {
    return {
      imported_images: [],
      imported_data: [],
      links: [],
      schema: [],
      show: false,
    };
  },
  methods: {
    submitHandler(data) {
      UserService.completeTask(data).then(
        (response) => {
          this.$router.go();
          return response;
        },
        (error) => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },
    rejectTask() {
      UserService.rejectTask(this.schema[1].value).then(
        (response) => {
          this.$router.go();
          return response;
        },
        (error) => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },
    passToExpert() {
      UserService.passToExpertTask(this.schema[1].value).then(
        (response) => {
          this.$router.go();
          return response;
        },
        (error) => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
};
</script>

<style scoped>
@import "../assets/scss/snow.scss";

.card-container.card {
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

div.flex-wrapper {
  display: flex !important;
}

.custom-link {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.3em;
  box-sizing: border-box;
  background-color: transparent;
  font-size: 0.9em;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  padding: 0.75em;
  display: block;
  font-weight: 400;
  line-height: 1.2em;
  margin: 0;
  border: 1px solid #964038;
  background-color: #964038;
  color: #fff;
  min-width: 0;
  width: auto;
  font-weight: 700;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
}

.margin-left {
  margin-left: 80px !important;
}

input[name="search-box"] {
  display: block;
  margin: 2rem auto;
  max-width: 500px;
  width: 95%;
  padding: 0.5rem 1rem;
}
.search-item {
  margin: 2rem auto;
  max-width: 500px;
  width: 95%;
  font-weight: 600;
  font-size: 1.25rem;
}
.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}
</style>
