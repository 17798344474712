<template>
  <div>
    <div class="row">
      <div class="search-wrapper panel-heading col-sm-12">
        <form @submit.prevent="AllProjects">
          <div>
            <label for="projectName">Project ID</label>
            <input
              type="text"
              id="projectName"
              v-model="formData.projectId"
              class="form-control"
            />
          </div>
          <div>
            <label for="projectName">Project Name</label>
            <input
              type="text"
              id="projectName"
              v-model="formData.projectName"
              class="form-control"
            />
          </div>
          <div>
            <label for="upload">Upload date from</label>
            <input
              type="text"
              id="upload"
              v-model="formData.uploadDateFrom"
              class="form-control"
            />
          </div>
          <div>
            <label for="upload">Upload date till</label>
            <input
              type="text"
              id="upload"
              v-model="formData.uploadDateTill"
              class="form-control"
            />
          </div>
          <div>
            <label for="upload">Billable</label>
            <input
              type="text"
              id="upload"
              v-model="formData.billable"
              class="form-control"
            />
          </div>
          <button type="submit">Search project</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
//import ProjectService from "../services/project.service";

export default {
  name: "ProjectSearch",
  data() {
    return {
      data: {
        projectId: "",
        projectName: "",
        uploadDateFrom: "",
        uploadDateTill: "",
        billable: Boolean(),
      },
    };
  },

  methods: {
    async create() {
      await axios
        .post(
          `https://jsonplaceholder.typicode.com/posts`
          /* `http://southvalidationapp-dev.ap-south-1.elasticbeanstalk.com/api/project/details` */
          /* `http://southvalidationapp-dev.ap-south-1.elasticbeanstalk.com/api/overview/getAllProjects/642d2f99535ba832a6fa61d3` */
        )
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error, "Failed");
        });
    },

    // getAllProjects
/*     AllProjects(data) {
      ProjectService.getFilteredProjects(this.$route.params.id, data).then(
        (response) => {
          this.schema = response.data;
          console.log(response, "Get Filtered Data");
        },
        (error) => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
          console.log(error, "Error");
        }
      );
    }, */

    submittedData() {
      console.log(this.formData);
    },
  },
};
</script>

<style scoped></style>
