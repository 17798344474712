<template>
    <div class="container">
      <div class="card" style="width: 100%">
            <b-tabs content-class="mt-3">
                <b-tab title="In QC" active>

                  <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Upload Name</th>
              <th scope="col">Project Name</th>
              <th scope="col">SLA Date</th>
              <th scope="col">SLA Time</th>
              <th scope="col">Priority</th>
              <th scope="col">Records</th>
              <th scope="col">Production Hours</th>
              <th scope="col">Error Rate</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in QC" :key="item._id">
              <td>{{ item.name }}</td>
              <td>{{ item.project_name }}</td>
              <td>{{ item.sla.substring(0, 10) }}</td>
              <td>{{ item.sla.substring(11, 16) }}</td>
              <td>{{ item.priority }}</td>
              <td>{{ item.finished_records }}</td>
              <td>{{ item.total_project_hours }}</td>
              <td>{{ item.error_rate }}%</td>
            </tr>
          </tbody>
        </table>


                </b-tab>
                <b-tab title="In Production">

                  <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Upload Name</th>
              <th scope="col">Project Name</th>
              <th scope="col">SLA Date</th>
              <th scope="col">SLA Time</th>
              <th scope="col">Priority</th>
              <th scope="col">Finished Records</th>
              <th scope="col">Hours so far</th>
              <th scope="col">Open Records</th>
              <th scope="col">Hours left</th>
              <th scope="col">QC total</th>
              <th scope="col">QC completed</th>
              <th scope="col">Error Rate</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in prod" :key="item._id">
              <td>{{ item.name }}</td>
              <td>{{ item.project_name }}</td>
              <td>{{ item.sla.substring(0, 10) }}</td>
              <td>{{ item.sla.substring(11, 16) }}</td>
              <td>{{ item.priority }}</td>
              <td>{{ item.finished_records }}</td>
              <td>{{ item.total_project_hours }}</td>
              <td>{{ item.open_records }}</td>
              <td>{{ item.left_project_hours }}</td>
              <td>{{ item.total_qc }}</td>
              <td>{{ item.finish_qc }}</td>
              <td>{{ item.error_rate }}%</td>
            </tr>
          </tbody>
        </table>


                </b-tab>
            </b-tabs>
        </div>
    </div>
  </template>
  
  <script>
  import AdminService from "../services/admin.service";





  
  export default {
    name: "OverallOverview",


    data: () => ({

      QC: [],
      prod: []


}),
async mounted () {
  this.loaded = false

  try {
    const { data } = await AdminService.getActiveUploadDetails()

    this.QC = data[0];
    this.prod =  data[1]

    this.loaded = true
  } catch (e) {
    console.error(e)
  }
},


created() {
document.title = "DAILY SUMMARY || INDIVILLAGE VALIDATION PLATFORM";
}

  
  };
  </script>
  
  <style scoped>
  @import "../assets/scss/snow.scss";
  
  .card-container.card {
    padding: 40px 40px;
  }
  
  .card {
    background-color: #f7f7f7;
    padding: 20px 25px 30px;
    margin: 0 auto 25px;
    margin-top: 50px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    display: inline-block !important;
  }
  
  div.flex-wrapper {
    display: flex !important;
  }
  
  .organe {
    border: 1px solid #b8a541 !important;
    background-color: #b8a541 !important;
  }
  
  .custom-link {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.3em;
    box-sizing: border-box;
    background-color: transparent;
    font-size: 0.9em;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
      Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    padding: 0.75em;
    display: block;
    width: 100%;
    font-weight: 400;
    line-height: 1.2em;
    margin: 0;
    border: 1px solid #41b883;
    background-color: #41b883;
    color: #fff;
    min-width: 0;
    width: auto;
    font-weight: 700;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
  }
  
  .margin-left {
    margin-left: 80px !important;
  }
  </style>
  