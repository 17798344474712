<template>
    <div class="container">
      <div class="card" style="width: 100%">
            <b-tabs content-class="mt-3">
                <b-tab v-for="project in projects" :key="project[0]" :title=project[0] >

        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Upload Name</th>
              <th scope="col">SLA Date</th>
              <th scope="col">SLA Time</th>
              <th scope="col">Billable</th>
              <th scope="col">Open Tasks</th>
              <th scope="col">Dactivate Link</th>

            </tr>
          </thead>
          <tbody>
            <tr v-for="item in project[1]" :key="item._id">
              <td>{{ item.Name }}</td>
              <td>{{ item.SLA_date.substring(0, 10) }}</td>
              <td>{{ item.SLA_date.substring(11, 16) }}</td>
              <td>
                <font-awesome-icon icon="check" v-if="item.Billable" />
                <font-awesome-icon icon="times-circle" v-else />
              </td>
              <td>{{ item.count }}</td>
              <td>
                <button
                  class="custom-link organe"
                  type="button"
                  @click="deactivate(item._id)"
                >
                  Deactivate
                </button>
              </td>
            </tr>
          </tbody>
        </table>


                </b-tab>
            </b-tabs>
        </div>
    </div>
  </template>
  
  <script>
  import AdminService from "../services/admin.service";





  
  export default {
    name: "OverallOverview",


    data: () => ({

      projects: []

}),
async mounted () {
  this.loaded = false

  try {
    const { data } = await AdminService.getActiveTasksUploads()

    this.projects = data;

    this.loaded = true
  } catch (e) {
    console.error(e)
  }
},


created() {
document.title = "OPEN TASKS || INDIVILLAGE VALIDATION PLATFORM";
},

methods: {


    deactivate(data) {
      AdminService.getdeativateUpload(data).then(
        response => {

          window.location.reload();
          return response

        },
        error => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },

  }



  
  };
  </script>
  
  <style scoped>
  @import "../assets/scss/snow.scss";
  
  .card-container.card {
    padding: 40px 40px;
  }
  
  .card {
    background-color: #f7f7f7;
    padding: 20px 25px 30px;
    margin: 0 auto 25px;
    margin-top: 50px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    display: inline-block !important;
  }
  
  div.flex-wrapper {
    display: flex !important;
  }
  
  .organe {
    border: 1px solid #b8a541 !important;
    background-color: #b8a541 !important;
  }
  
  .custom-link {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.3em;
    box-sizing: border-box;
    background-color: transparent;
    font-size: 0.9em;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
      Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    padding: 0.75em;
    display: block;
    width: 100%;
    font-weight: 400;
    line-height: 1.2em;
    margin: 0;
    border: 1px solid #41b883;
    background-color: #41b883;
    color: #fff;
    min-width: 0;
    width: auto;
    font-weight: 700;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
  }
  
  .margin-left {
    margin-left: 80px !important;
  }
  </style>
  