<template>
  <div  class="container">
    <div v-for="project in schema" :key="project.name" class="card">
      <div v-if="schema.length == 0" class="card">
        <h3>No QC Tasks Available</h3>
      </div>
      <div class="card-body" v-else>
        <h5 class="card-title">{{ project.name }} Validation</h5>
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Upload Name</th>
              <th scope="col">SLA Date</th>
              <th scope="col">Available QC Tasks</th>
              <th scope="col">Link</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in project.uploads" :key="item._id">
              <td>{{ item.name }}</td>
              <td>{{ item.SLA.substring(0, 10) }}</td>
              <td>{{ item.count }}</td>
              <td>
                <router-link v-if="item['type']==='multiRecord'" :to="'qcmulti/' + item._id" class="custom-link">
                  Start QC
                </router-link>
                <router-link v-else :to="'qc/' + item._id" class="custom-link">
                  Start QC
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "../services/user.service";

export default {
  name: "UserItem",

  created() {
    document.title = "QC MODULE || INDIVILLAGE VALIDATION PLATFORM";
    UserService.getAllQCTasks().then(
      (response) => {
        this.schema = response.data;
      },
      (error) => {
        this.content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
      }
    );
  },

  data() {
    return {
      schema: [],
    };
  },
};
</script>

<style scoped>
@import "../assets/scss/snow.scss";

.card-container.card {
  padding: 40px 40px;
}

.card {
  width: 100%;
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

div.flex-wrapper {
  display: flex !important;
}

.custom-link {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.3em;
  box-sizing: border-box;
  background-color: transparent;
  font-size: 0.9em;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  padding: 0.75em;
  display: block;
  width: 100%;
  font-weight: 400;
  line-height: 1.2em;
  margin: 0;
  border: 1px solid #41b883;
  background-color: #41b883;
  color: #fff;
  min-width: 0;
  width: auto;
  font-weight: 700;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
}

/* Media Query for Mobile Devices */
@media (max-width: 480px) {
  .card {
    width: 150%;
  }
}
</style>
