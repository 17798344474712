<template>

  <div class="container">
  <div class="card" style="width: 100%;">
    <div class="card-body">
      <h5 class="card-title">Team {{schema[0][0]}} (average for today: {{ schema[0][1] }} tasks with 
        <span v-if="schema[0][2]>0">{{ schema[0][2] }} hours and</span> {{ schema[0][3] }} minutes of work)
        </h5>
      <table class="table table-striped">
    <thead>
      <tr>
        <th scope="col">User Email</th>
        <th scope="col">Stats</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in schema[1]" :key="item.email">
        <td>{{item.email}}</td>
        <td>{{item.FirstName}} {{item.NastName}} has completed {{ item.count }} tasks with 
        <span v-if="item.hours>0">{{ item.hours }} hours and</span> {{ item.minutes }} minutes of work today</td>
      </tr>
    </tbody>
  </table>
    </div>
  
  </div>
  </div>
  
  
  
  </template>


<script>
import ModService from '../services/mod.service';

export default {
    name: 'ModUserProductivity',

    created() {
      document.title = "USER PRODUCTIVITY || INDIVILLAGE VALIDATION PLATFORM"
      ModService.loadModPerformance(this.$route.params.id).then(
        response => {
          this.schema = response.data;
        },
        error => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();

        }
      );
    },

    data () {
      return {
        values: {},
        schema: []
      }
    }
}

</script>

<style scoped>

@import '../assets/scss/snow.scss';


.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;

}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  display: inline-block !important;
}

div.flex-wrapper {
    display: flex !important;
}

</style>
