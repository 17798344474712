

<template>
  <div class="container">

    <div v-if="response_code == 200" class="card" style="width: 100%">
            <b-tabs content-class="mt-3">
                <b-tab title="General Overview" active>

                  <div>

                       
                         <b-container class="bv-example-row">
                           <b-row>
                             <b-col><h5 class="card-title">Upload: <b>{{ job.payload.client_mappings.mx }} </b></h5></b-col>
                             <b-col>
                               <b-button v-if="job.status == 'Open'" variant="dark" style="float: right;">{{ job.status}}</b-button>
                               <b-button v-else-if="job.status == 'Rejected'" variant="danger" style="float: right;">{{ job.status}}</b-button>
                               <b-button v-else-if="job.status == 'Finished'" variant="success" style="float: right;">{{ job.status}}</b-button>
                               <b-button v-else variant="warning" style="float: right;">{{ job.status}}</b-button>
                             </b-col>
                           </b-row>
                         </b-container> 
 
                         
                         <b-container class="bv-example-row">
                           <b-row>
                             <b-col><p>Job Type: <b>{{ job.payload.job_type }}</b><br>
                              Request Size: <b>{{ job.payload.job_type }}</b><br>
                              Region: <b>{{ job.payload.client_mappings.region }}</b><br>
                              Wi: <b>{{ job.payload.client_mappings.wi }}</b><br>
                              Mx level: <b>{{ job.payload.client_mappings.mx_levle }}</b><br>
                              Priority: <b>{{ job.payload.client_mappings.priority }}</b><br>
                              Taxonomy: <b>{{ job.payload.client_mappings.taxonomy }}</b><br>
                              Jira link: <b>{{ job.payload.client_mappings.jira_link }}</b><br>
                              Business ID: <b>{{ job.payload.client_mappings.business_id }}</b><br>
                              Request type: <b>{{ job.payload.client_mappings.request_type }}</b><br>
                              Triage notes: <b>{{ job.payload.client_mappings.triage_notes }}</b><br> <br>
                              Missing Fields: <b><span v-for="column in columns_missing" :key="column._id">'{{ column }}', </span></b> <br><br>
                              Redundant Fields: <b><span v-for="column in redundant_columns" :key="column._id">'{{ column }}', </span></b>
                              </p>
                             </b-col>
                           </b-row>
                         </b-container> 
                       
 
                     </div>


                </b-tab>

                <b-tab title="Fields Summary">
                  <div>
                  <vue-good-table
                    :columns='[{field:"field",label:"Field Name"},{field:"total",label:"Filled in values"},{field:"prop",label:"% filled in"},{field:"unique",label:"Unique count"},{field: "total_nl", label: "Filled in values (non-excl.)"}, {field: "prop_nl", label: "% filled in (non-excl.)"}, {field: "unique_nl", label: "Unique count (non-excl.)"}]'
                    :rows="summary">
                    <div slot="emptystate">
                    Data is being loaded...
                  </div>
                </vue-good-table>
                </div>
                </b-tab>

                <b-tab title="Data Review">
                  <div>
                    <vue-good-table
                        :columns="columns"
                        :rows="rows.slice(0,1000)"
                        styleClass="vgt-table condensed"
                        :pagination-options="{
                        enabled: true,
                        mode: 'records',
                        perPage: 100,
                        position: 'top',
                        perPageDropdown: [100, 200, 500],
                        dropdownAllowAll: false,
                        setCurrentPage: 1,
                        jumpFirstOrLast : true,
                        firstLabel : 'First Page',
                        lastLabel : 'Last Page',
                        nextLabel: 'next',
                        prevLabel: 'prev',
                        rowsPerPageLabel: 'Rows per page',
                        ofLabel: 'of',
                        pageLabel: 'page', // for 'pages' mode
                        allLabel: 'All',
                        infoFn: (params) => `my own page ${params.firstRecordOnPage}`, 
                      }">
                        <div slot="emptystate">
                        Data is being loaded...
                      </div>
                    </vue-good-table>
                    </div>


                </b-tab>
                <b-tab title="Actions">

                  <b-row>
                      <b-col><p>Project Name: <b>{{ projects.name }}</b><br></p></b-col>
                 </b-row>

                  <div class="input-group">
                    <span class="input-group-text">Add special instructions</span>
                    <textarea id="instructions" class="form-control" aria-label="Add special instructions" ></textarea>
                  </div>
                  <br>
                
                  <button class="btn btn-secondary custom-link green" type="submit"
                  @click="process([job._id.toString(),projects._id.toString()])">
            
                  Add to production
                  </button>

                  <br>

                  <br>

                  <button class="btn btn-secondary custom-link organe" type="submit"
                  @click="manual(job._id.toString())">

                  Manually Process the file
                  </button>

                  <br><br>

                  <div class="input-group">
                    <span class="input-group-text">Add rejection comments</span>
                    <textarea id="rejections" class="form-control" aria-label="Add rejection commnets" ></textarea>
                  </div>
                  <br>

                  <button class="btn btn-secondary custom-link organe" type="submit"
                  @click="refuse(job._id.toString())">
            
                  Reject Upload
                  </button>
                  <br><br>

                  <td><a :href="job.payload.delivery_url" target="_new">Review file</a></td>
                




                </b-tab>
            </b-tabs>
        </div>

        <div v-else-if="response_code == 206" class="card" style="width: 100%">
            <b-tabs content-class="mt-3">

                <b-tab title="Actions">

                  <b-col><h5 class="card-title">Error: <b>{{ error_string }} </b></h5></b-col> 

                  <br>

                  <td><a :href="job.payload.delivery_url" target="_new">Review file</a></td>

                  <br>
                  <br>

                  <button class="btn btn-secondary custom-link organe" type="submit"
                  @click="manualcomplete(job._id.toString())">

                  Manually Process the file
                  </button>

                  <br><br>

                  <div class="input-group">
                    <span class="input-group-text">Add rejection comments</span>
                    <textarea id="rejections" class="form-control" aria-label="Add rejection commnets" ></textarea>
                  </div>
                  <br>

                  <button class="btn btn-secondary custom-link organe" type="submit"
                  @click="refuse(job._id.toString())">
            
                  Reject Upload
                  </button>
                  <br><br>

                  
                




                </b-tab>
            </b-tabs>
        </div>
    <div v-else>
      <h1>LOADING</h1>
      </div>
    </div>





</template>


<script>
import UploadService from "../services/integrations.service";

export default {
  name: "JobImport",

  created() {
    document.title = "ADMIN || INDIVILLAGE VALIDATION PLATFORM";
    UploadService.previewImportCSV(this.$route.params.id+"/"+this.$route.params.prid).then(
      (response) => {

        if (response.status == 200){
          this.response_code = response.status
          this.columns = response.data[0];
          this.rows = response.data[1];
          this.summary = response.data[2];
          this.projects = response.data[3];
          this.job = response.data[4];
          this.columns_missing = response.data[5];
          this.redundant_columns = response.data[6]
          }

        else  {
          this.response_code = response.status
          this.error_string = response.data[0]
          this.job = response.data[2]
          this.projects = response.data[1]
        }
      },
      (error) => {
        this.content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
      }
    );
  },

  data() {
    return {
      response_code: 0,
      error_string: "",
      rows : [],
      columns: [],
      summary: [],
      projects: {},
      job: {},
      columns_missing: [],
      redundant_columns: []
    };
  },

  methods: {
    process(data) {
      UploadService.addToProd([data,{instructions:document.getElementById("instructions").value}]).then(
        (response) => {
          var link_to_push = "/import/jobscsv";
          this.$router.push(link_to_push);
          return response;
        },
        (error) => {
          alert(
            error.response.data.message
          );
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },
    manual(data) {
      UploadService.manualAddToProd(data).then(
        (response) => {
          var link_to_push = "/import/jobscsv";
          this.$router.push(link_to_push);
          return response;
        },
        (error) => {
          alert(
            error.response.data.message
          );
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },
    manualcomplete(data) {
      UploadService.manualCompleteAddToProd(data).then(
        (response) => {
          var link_to_push = "/import/jobscsv";
          this.$router.push(link_to_push);
          return response;
        },
        (error) => {
          alert(
            error.response.data.message
          );
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },
    refuse(data) {
      UploadService.refuseAddToProd([data,{reason:document.getElementById("rejections").value}]).then(
        (response) => {
          var link_to_push = "/import/jobscsv";
          this.$router.push(link_to_push);
          return response;
        },
        (error) => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    }

  }
};
</script>

<style scoped>
@import "../assets/scss/snow.scss";

.card-container.card {
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  display: inline-block !important;
}

div.flex-wrapper {
  display: flex !important;
}

.organe {
  border: 1px solid #b8a541 !important;
  background-color: #b8a541 !important;
}

.custom-link {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.3em;
  box-sizing: border-box;
  background-color: transparent;
  font-size: 0.9em;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  padding: 0.75em;
  display: block;
  font-weight: 400;
  line-height: 1.2em;
  margin: 0;
  border: 1px solid #41b883;
  background-color: #41b883;
  color: #fff;
  min-width: 0;
  width: 14em;
  font-weight: 700;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
}

.margin-left {
  margin-left: 80px !important;
}
</style>
