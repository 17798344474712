<template>

<div class="container">
        <FormulateForm
          @submit="submitHandler"
          v-model="values"
          :schema="schema"
        />
</div>
</template>


<script>
import ProjectService from '../services/project.service';

export default {
    name: 'NewProjectItem',

    created() {
      document.title = "ADMIN || INDIVILLAGE VALIDATION PLATFORM"
      ProjectService.getNew().then(
        response => {
          this.schema = response.data;
          console.log(this.schema, "New Project Schema")
        },
        error => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();

        }
      );
    },

    data () {
      return {
        values: {},
        schema: []
      }
    },


    methods: {
      submitHandler (data) {
          ProjectService.createNew(data).then(
            response => {
              this.$router.push('/admin');
              return response
            },
            error => {
              this.content =
                (error.response && error.response.data) ||
                error.message ||
                error.toString();

            }
          );
            }
    }
}

</script>

<style scoped>

@import '../assets/scss/snow.scss';


.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;

}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  display: inline-block !important;
}

div.flex-wrapper {
    display: flex !important;
}

</style>
