<template>
<div class="container">
<div class="card" style="width: 100%;">
  <div class="card-body">
    <h3 class="card-title">Upload: {{input.name}}
    </h3>

    <h5 v-if="schema.billable">Project is billable with SLA date of {{input.SLA_date.substring(0, 10) }} {{ input.SLA_date.substring(11, 16) }} (UTC)</h5>
    <h5 v-else>Project is non-billable with SLA date of {{input.SLA_date.substring(0, 10) }} {{ input.SLA_date.substring(11, 16) }} (UTC)</h5>

    <FormulateForm
        @submit="changeSLA"
        v-model="values"
        :schema="schema"
      />

      <br>

      <FormulateForm
        @submit="changeInstructions"
        v-model="values"
        :schema="schema2"
      />

<br>

    <button
                  class="custom-link"
                  type="button"
                  @click="deactivate()"
                >
                  Deactivate Upload
                </button>

  </div>

</div>
</div>
</template>


<script>
import AdminService from '../services/admin.service';

export default {
    name: 'NewModUserUpdate',

    created() {
      document.title = "PRODUCTION || INDIVILLAGE VALIDATION PLATFORM"
      AdminService.getloadModifyUpload(this.$route.params.id).then(
        response => {
          this.input = response.data;
        },
        error => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();

        }
      );
    },

    data () {
      return {
        input: {},
        values: {},
        schema: [
        {
          type: "datetime-local",
          name: "SLA",
          label: "SLA Date",
          validation: "required",
        },
        {
          type: "submit",
          label: "Change SLA date",
        }
      ],
      schema2: [
        {
          type: "text",
          name: "special_instructions",
          label: "Special Instructions",
        },
        {
          type: "submit",
          label: "Update Special Instructions"
        }
      ]
    
      }
    },

    methods: {
      deactivate () {
          AdminService.getdeativateUpload(this.$route.params.id).then(
            response => {
              var link_to_push = '/project/'+this.input.project
              this.$router.push(link_to_push);
              return response
            },
            error => {
              this.content =
                (error.response && error.response.data) ||
                error.message ||
                error.toString();

            }
          );
            },
            changeSLA (schema) {
          AdminService.getupdateSLADate([this.$route.params.id,schema]).then(
            response => {
              var link_to_push = '/project/'+this.input.project
              this.$router.push(link_to_push);
              return response
            },
            error => {
              this.content =
                (error.response && error.response.data) ||
                error.message ||
                error.toString();

            }
          );
            },
            changeInstructions (schema) {
          AdminService.getupdateInstructions([this.$route.params.id,schema]).then(
            response => {
              var link_to_push = '/project/'+this.input.project
              this.$router.push(link_to_push);
              return response
            },
            error => {
              this.content =
                (error.response && error.response.data) ||
                error.message ||
                error.toString();

            }
          );
            }

    }
}

</script>

<style scoped>

@import '../assets/scss/snow.scss';


.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;

}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  display: inline-block !important;
}

div.flex-wrapper {
    display: flex !important;
}


.custom-link {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.3em;
  box-sizing: border-box;
  background-color: transparent;
  font-size: 0.9em;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  padding: 0.75em;
  display: block;
  width: 100%;
  font-weight: 400;
  line-height: 1.2em;
  margin: 0;
  border: 1px solid #963637;
  background-color: #963637;
  color: #fff;
  min-width: 0;
  width: auto;
  font-weight: 700;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
}


</style>
