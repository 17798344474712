<template>
  <div class="container container_mod">

    <div class="card" style="width: 100%">

      <div class="scroll">

      <table class="table table-striped">
                    <thead>
                      <tr>
                        <td v-for="headers in schema" :key="headers._id">{{ headers.name }}</td>
                      </tr>
                    </thead>
                    <tbody>
                      
                      <tr v-for="record in data" :key="record._id">
                        <td v-for="headers in schema" :key="headers._id">
                          <span v-if="headers['type']==='image'"><img :src="record[headers.name]" alt="" class="img_size"></span>
                          <span v-else-if="headers['type']==='url'"><a :href="record[headers.name]" target=”_blank”>Link</a>  </span>
                          <span v-else-if="headers['type']==='dropdown' && output[record._id]['Audit Result']==='No review needed'"><b-form-input class="form-control inputs_big" v-model="output[record._id][headers.name]" :disabled="true"></b-form-input></span>
                          <span v-else-if="headers['type']==='dropdown'"><b-form-select class="form-control inputs_big" v-model="output[record._id][headers.name]" :options="headers.list"></b-form-select></span>
                          <span v-else-if="headers['type']==='input_text'"><b-form-input class="inputs_big" v-model="output[record._id][headers.name]" :placeholder="record[headers.name]" :required="output[record._id]['Audit Result']!='Unique Item'" :disabled="output[record._id]['Audit Result']==='Unique Item'"></b-form-input></span>
                          <span v-else>{{record[headers.name]}}</span>
                          
                          
                        
                        
                        </td>
                      </tr>
                     
                    </tbody>
                  </table>  

                </div>
                <br><br>

                  <div>

                  <button class="btn btn-secondary btn-success custom-link green" type="submit"
                  @click="submitHandler()">
                  Submit
                  </button>

                  <button v-if="expert" class="btn btn-secondary custom-link orange margin-left" type="submit"
                  @click="passToExpert()">         
                  Pass To Expert
                  </button>


                  </div>



    </div>


  </div>
</template>

<script>
import UserService from "../services/user.service";
import { reactive } from "vue";



export default {
  name: "UserItem",
  setup() {
    const state = reactive({
      searchResults: [],
      searchResultsPLU: [],
      searchResultsPLUStock: [],
      searchResultsBrand: [],
      noSearch: true,
      noPLUSearch: true,
      noPLUStockSearch: true,
      noBrandSearch: true,
    });
    function search(e) {
      let search = e.target.value.trim();
      UserService.searchItemName({ search: search })
        .then((res) => {
          if (search === "") {
            state.noSearch = true;
            return;
          }
          state.noSearch = false;
          state.searchResults = res.data.map((title) => title.name);
        })
        .catch((err) => {
          throw new Error(err);
        });
    }
    function searchPLU(e) {
      let searchPLU = e.target.value.trim();
      UserService.searchPLU({ search: searchPLU })
        .then((res) => {
          if (search === "") {
            state.noPLUSearch = true;
            return;
          }
          state.noPLUSearch = false;
          state.searchResultsPLU = res.data;
        })
        .catch((err) => {
          throw new Error(err);
        });
    }
    function searchPLUStock(e) {
      let searchPLUstock = e.target.value.trim();
      UserService.searchPLUStock({ search: searchPLUstock })
        .then((res) => {
          if (search === "") {
            state.noPLUStockSearch = true;
            return;
          }
          state.noPLUStockSearch = false;
          state.searchResultsPLUStock = res.data;
        })
        .catch((err) => {
          throw new Error(err);
        });
    }
    function searchBrand(e) {
      let searchBrand = e.target.value.trim();
      UserService.searchBrand({ search: searchBrand })
        .then((res) => {
          if (search === "") {
            state.noBrandSearch = true;
            return;
          }
          state.noBrandSearch = false;
          state.searchResultsBrand = res.data;
        })
        .catch((err) => {
          throw new Error(err);
        });
    }
    return { search, searchPLU,searchBrand,searchPLUStock, state };
  },

  created() {
    document.title = "VALIDATION || INDIVILLAGE VALIDATION PLATFORM";
    UserService.getTaskMulti(this.$route.params.id).then(
      (response) => {
        this.schema = response.data[0];
        this.data = response.data[1];
        this.output = response.data[2];
        this.expert = response.data[3];

      },
      (error) => {
        alert(error.response.data.message);
        this.$router.push("/user");
        this.content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
      }
    );
    this.countDownTimer();
  },

  data() {
    return {
      schema: [],
      data: {},
      output: {},
      countDown: 1800,
      expert: true
    };
  },
  methods: {
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      } else {
        this.$router.push("/user");
      }
    },
    submitHandler() {
      UserService.completeTaskMulti(this.output).then(
        (response) => {
          this.$router.go();
          return response;
        },
        (error) => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },
    rejectTask() {
      UserService.rejectTaskMulti(this.schema[1].value).then(
        (response) => {
          this.$router.go();
          return response;
        },
        (error) => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },
    passToExpert() {
      UserService.passToExpertTaskMulti(this.output.taskid).then(
        (response) => {
          this.$router.go();
          return response;
        },
        (error) => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },
    suggestionTitleSelected(i) {
      this.values["Woflow Cleaned Item Name"] = i;
    },
    suggestionPLUSelected(i) {
      if (!(this.positions["photo_id"] == null)) {
        this.values["PLU Photo ID"] = i.photo_id;
      }
      if (!(this.positions["qty"] == null)) {
        this.values["approximate_sold_as_quantity"] = i.qty;
      }
      if (!(this.positions["unit"] == null)) {
        this.values["approximate_sold_as_unit"] = i.unit;
      }
    },
    suggestionBrandSelected(i) {
      if (!(this.positions["brand_name"] == null)) {
        this.values["Brand Name"] = i;
      } else if (!(this.positions["brand_path"] == null)) {
        this.values["Woflow brand_path"] = i;
      } else if (!(this.positions["correct_brand_path"] == null)) {
        this.values["Correct Brand Path"] = i;
      }
    },
  },
};
</script>

<style scoped>
@import "../assets/scss/snow.scss";


.container_mod {
  width: 100% !important;
  max-width: 100% !important;
}






.search-results {
  font-size: 15px;
  color: #41b883;
}

.card-container.card {
  padding: 40px 40px;
}

.additional {
  max-width: 50%;
}

.validate-image {
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

div.flex-wrapper {
  display: flex !important;
}

.green {
  background-color: #41b883 !important;
  border: 1px solid #41b883!important;
}


.custom-link {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.3em;
  box-sizing: border-box;
  background-color: transparent;
  font-size: 0.9em;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  padding: 0.75em;
  display: block;
  font-weight: 400;
  line-height: 1.2em;
  margin: 0;
  color: #fff;
  min-width: 0;
  width: auto;
  font-weight: 700;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
}

.margin-left {
  margin-left: 80px !important;
}

.orange {
        background-color: #b88e41 !important;
        border: 1px solid #b88e41 !important;
    }


.img_size {
  max-width: 150px;
}

.scroll {
    overflow-x: auto;

}

.inputs_big {
  width: 150px !important;
}

</style>
