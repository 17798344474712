<template>
  <div class="container">
    <div class="card card-container">
      <FormulateForm
        @submit="submitHandler"
        v-model="values"
        :schema="schema"
      />
    </div>
  </div>
</template>

<script>
import ProjectService from "../services/upload.service";

export default {
  name: "UploadItem",

  data() {
    return {
      values: {},
      schema: [
        {
          component: "h3",
          children: "File Upload",
        },
        {
          type: "text",
          name: "name",
          label: "Upload Name",
          validation: "required",
        },
        {
          type: "text",
          name: "businessId",
          placeholder: "Enter business ID",
          label: "Business ID",
        },
        {
          type: "select",
          options: ["P0", "P1", "P2", "P3"],
          name: "priority",
          placeholder: "Enter priority",
          label: "Priority",
        },
        {
          type: "select",
          options: ["Local", "Enterprise"],
          placeholder: "Enter MX Level",
          name: "mx level",
          label: "MX Level",
        },
        {
          type: "select",
          options: [
            "Lunch",
            "Expansion",
            "Holiday SKUs",
            "L4 Backfill",
            "Integration Conversion",
            "SKU Updates",
            "Project/Other",
            "Aldi",
            "Sprouts",
            "Grocery Outlet",
            "P0 Project",
            "QA Check",
          ],
          name: "request type",
          placeholder: "Enter Request Type",
          label: "Request Type",
        },
        {
          type: "select",
          options: [
            "Quantanite",
            "Woflow",
            "other"
          ],
          name: "vendor",
          placeholder: "Enter Build Vendor",
          label: "Build Vendor",
          validation: "required",
        },
        {
          type: "select",
          options: [
          'CnG',
          'Pets (by animal)',
          'Beauty',
          'Home',
          'Retail',
          'Sports',
          'Custom',
          'Sports and Apparel',
          'Alcohol',
          'Outdoor',
          'Electronics',
          'Office',
          'Retail other',
          'Party Taxonomy (by occasion)',
          'Vitamin Taxonomy',
          'Sports Taxonomy',
          'Party',
          'Female Apparel',
          'Other',
          'Custom (Female Apparel)'
          ],
          name: "taxonomy",
          placeholder: "Enter Taxonomy",
          label: "Taxonomy",
          validation: "required",
        },
        {
          type: "select",
          options: ["Marketplace", "New Verticals", "Alcohol", "CnG"],
          name: "marketplace",
          placeholder: "Enter Marketplace",
          label: "NV vs Marketplace",
        },
        {
          type: "datetime-local",
          name: "SLA",
          label: "SLA Date",
          validation: "required",
        },
        {
          type: "checkbox",
          name: "billable",
          label: "Is it billable?",
          checked: true

        },
        {
          type: "file",
          name: "file",
          label: "Select your documents to upload",
          validation: "mime:text/csv",
        },
        {
          type: "submit",
          label: "Upload a file",
        },
      ],
    };
  },
  methods: {
    submitHandler(data, schema) {
      ProjectService.createNew([this.$route.params.projectid, data, schema]).then(
        (response) => {
          var link_to_push = "/project/" + this.$route.params.projectid;
          this.$router.push(link_to_push);
          return response;
        },
        (error) => {
          alert(
            error.response.data.message +
              "\n" +
              error.response.data.missing_fields.join("\n")
          );
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
      console.log(this.submitHandler);
      console.log("Form submitted!", this.values);
    },
  },
};
</script>

<style scoped>
@import "../assets/scss/snow.scss";

.card-container.card {
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

div.flex-wrapper {
  display: flex !important;
}

/* Media Query for Mobile Devices */
@media (max-width: 480px) {
}
</style>
