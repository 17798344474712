import axios from 'axios';
import authHeader from './auth-header';

import {URL} from '../models/constants';

const API_URL = URL+'upload/';

class UserService {

  createNew(data) {
    return axios.post(API_URL+'file/' + data[0], data[1], { headers: authHeader() });

  }

  upload_final_qc(data) {
    return axios.post(API_URL+'final_qc', data, { headers: authHeader() });

  }

  createCertNew(data) {
    return axios.post(API_URL+'Certfile/' + data[0], data[1], { headers: authHeader() });

  }


  loadNewValidation(data) {
    return axios.get(API_URL+'validation/' + data, { headers: authHeader() });

  }

  createNewValidation(data) {
    return axios.post(API_URL+'validation/' + data[0], data[1], { headers: authHeader() });

  }

  loadNewLookup(data) {
    return axios.get(API_URL+'lookup/' + data, { headers: authHeader() });

  }

  createNewLookup(data) {
    return axios.post(API_URL+'lookup/' + data[0], data[1], { headers: authHeader() });

  }

  createGolden(data) {
    return axios.post(API_URL+'golden', data, { headers: authHeader() });

  }

  createPLU(data) {
    return axios.post(API_URL+'plu', data, { headers: authHeader() });

  }

  createBrand(data) {
    return axios.post(API_URL+'brand', data, { headers: authHeader() });

  }

  createCategory(data) {
    return axios.post(API_URL+'category', data, { headers: authHeader() });

  }

  createReccsCat(data) {
    return axios.post(API_URL+'reccscat', data, { headers: authHeader() });

  }

  updateUpload(data) {
    return axios.post(API_URL+'update', data, { headers: authHeader() });

  }

  getImportJobs() {
    return axios.get(API_URL+'getimportjobs', { headers: authHeader() });

  }

  processImportJob(data) {
    return axios.get(API_URL+'importjob/'+data, { headers: authHeader() });

  }

  manualImportJob(data) {
    return axios.get(API_URL+'importjob_manual/'+data, { headers: authHeader() });

  }

  refuseImportJob(data) {
    return axios.get(API_URL+'importjob_reject/'+data, { headers: authHeader() });

  }

  refuseImportJobManual(data) {
    return axios.get(API_URL+'importjob_reject_manual/'+data, { headers: authHeader() });

  }


  getImportJobsCSV() {
    return axios.get(API_URL+'getimportjobsCSV', { headers: authHeader() });

  }

  previewImportCSV(data) {
    return axios.get(API_URL+'importGS/'+data, { headers: authHeader() });

  }

  processImportJobCSV(data) {
    return axios.get(API_URL+'importjobcsv/'+data, { headers: authHeader() });

  }


  refuseImportJobCSV(data) {
    return axios.get(API_URL+'importjob_rejectcsv/'+data, { headers: authHeader() });

  }

  

}

export default new UserService();
