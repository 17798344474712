<template>

    <div class="container container_mod">


<div class="card" style="width: 100%">


  <div class="scroll">

  <table class="table table-striped">
                <thead>
                  <tr>
                    <td v-for="headers in schema" :key="headers.name">{{ headers.name }}</td>
                  </tr>
                </thead>
                <tbody>
                  
                  <tr v-for="record in data" :key="record._id">
                    <td v-for="headers in schema" :key="headers._id">
                      <span v-if="headers['type']==='image'"><img :src="record[headers.name]" alt="" class="img_size"></span>
                      <span v-else-if="headers['type']==='url'"><a :href="record[headers.name]" target=”_blank”>Link</a>  </span>
                      <span v-else>{{record[headers.name]}}</span>
                      
                      
                    
                    
                    </td>
                  </tr>
                 
                </tbody>
              </table> 
              
            </div>

</div>
<div class="card" style="width: 100%">
          <div>
        <br><br>
        <h5>Submit QC</h5>

        What was the error? <br>
        <select id="error_type" style="width:100%; height:150px" multiple v-model="selectedErrors">
          <option value="Incorrect Exclude Reason">Incorrect Exclude Reason</option>
          <option value="Incorrect Invalid Reason">Incorrect Invalid Reason</option>
          <option value="Missing Exclude Reason">Missing Exclude Reason</option>
          <option value="Missing Invalid Reason">Missing Invalid Reason</option>
          <option value="Need to select unbranded">Need to select unbranded</option>
          <option value="Photo Error">Photo Error</option>
          <option value="IQA Photo - No Error">IQA Photo - No Error</option>
          <option value="PLU error">PLU error</option>
          <option value="Brand error">Brand error</option>
          <option value="Extra Words">Extra Words</option>
          <option value="Missing Words">Missing Words</option>
          <option value="Incorrect Size/UOM">Incorrect Size/UOM</option>
          <option value="Misising Size/UOM">Misising Size/UOM</option>
          <option value="Incorrect Word Order">Incorrect Word Order</option>
          <option value="Need to Exclude but Worked">Need to Exclude but Worked</option>
          <option value="Need to Work but Excluded">Need to Work but Excluded</option>
          <option value="Incorrect Category">Incorrect Category</option>
          <option value="Missing Category">Missing Category</option>
          <option value="Missing item type">Missing item type</option>
          <option value="Incorrect item type">Incorrect item type</option>
          <option value="Spelling Error Accute Symbols">Spelling Error Accute Symbols</option>
          <option value="Spelling Error Space error">Spelling Error Space error</option>
          <option value="Spelling Error_Sentance Case">Spelling Error_Sentance Case</option>
          <option value="URPC Linking MPC & URPC Verification Error">URPC Linking MPC & URPC Verification Error</option>
          <option value="URPC Linking QIF & MPC Verification Error">URPC Linking QIF & MPC Verification Error</option>
          <option value="URPC Linking QIF & URPC Verification Error">URPC Linking QIF & URPC Verification Error</option>
          <option value="URPC Linking URPC Name Verification Error">URPC Linking URPC Name Verification Error</option>
          <option value="New Error (Update Comments)">New Error (Update Comments)</option>
          <option value="QA Vx Audit">QA Vx Audit</option>
          <option value="Wrong - Altered Brand">Wrong - Altered Brand</option>
          <option value="Wrong - Altered Item Name">Wrong - Altered Item Name</option>
          <option value="Wrong - Altered Variants">Wrong - Altered Variants</option>
          <option value="Wrong - Mismatched Row">Wrong - Mismatched Row</option>
          <option value="No Error (Item is Correct)">No Error (Item is Correct)</option>
          <option value="Wrong - L1">Wrong - L1</option>
          <option value="Wrong - L2">Wrong - L2</option>
          <option value="Wrong - L3">Wrong - L3</option>
          <option value="Wrong - L4">Wrong - L4</option>
        </select>

        <br>

        <input id="message" style="width:100%" placeholder="Add your comments" />
        
        <br><br>
        Which users made an error?<br>

        <span v-for="item4 in tasks_data" :key="item4.id">
        <input type="checkbox" :id="item4.users.email" :value="item4.users.email" v-model="checkedNames" >
        <label :for="item4.users.email">{{item4.users.email}}</label></span>

        
        
        <br><br>
        
        <button class="custom-link green" type="button" @click="correct">
          Correct
        </button>

        <button class="custom-link margin-left orange" type="button" @click="inconsistent">
          Inconsistent
        </button>
    
        <button class="custom-link margin-left" type="button" @click="incorrect">
          Incorrect
        </button>

        <button class="custom-link margin-left grey" type="button" @click="onhold">
          On Hold
        </button>
    
        </div>
</div>
<div class="card" style="width: 100%;" v-for="item3 in tasks_data" :key="item3.id">
  <div class="card-body">
        <h5 class="card-title">{{item3.projecttasks.name}} - Sumbission Details</h5>
          <div>{{item3.users.email}}</div>
          <div>Status: <b>{{item3.status}}</b></div>
          <table class="table table-striped" v-if="item3.payload" >
          <thead >
            <tr>
              <th scope="col">Submitted Value</th>
            </tr>
          </thead>
          <tbody>
              <tr v-for="key in Object.keys(item3.payload)" :key="key.id">
              <td><b>{{item3.payload[key]}}</b></td>
            </tr>
          </tbody>
        </table>
      </div>
</div>

    </div>
    
    </template>
    
    <script>
    import UserService from '../services/user.service';
    

    export default {
    name: 'QCItem',    
    created() {
      document.title = "QC MODULE || INDIVILLAGE VALIDATION PLATFORM"
      UserService.getQCTaskMulti(this.$route.params.id).then(
        response => {
          this.schema = response.data[0];
          this.data = response.data[1];
          this.tasks_data = response.data[2];
          this.QCtaskid = response.data[3];
        },
        error => {
          alert(error.response.data.message)
          this.$router.push('/qc');
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
    
        },
      );

    },
    
    data () {
      return {
        schema: [],
        data: {},
        output: {},
        tasks_data: {},
        QCtaskid: '',
        checkedNames: [],
        selectedErrors: []

    }},
    methods: { 
        correct () {
      UserService.submitQCTaskMulti({QCtaskid: this.QCtaskid, verdict: true, consistency: true, message:document.getElementById("message").value}).then(
        response => {
          this.$router.go();
          return response
        },
        error => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();

        }
      );
        },
        incorrect () {
      UserService.submitQCTaskMulti({QCtaskid: this.QCtaskid,
          verdict: false,
          consistency: true,
          message:document.getElementById("message").value,
          error_type:this.selectedErrors.join("|"),
          users:this.checkedNames
      }).then(
        response => {
          this.$router.go();
          return response
        },
        error => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();

        }
      );
        },
        inconsistent () {
      UserService.submitQCTaskMulti({
        QCtaskid: this.QCtaskid, 
        verdict: true, 
        consistency: false, 
        message:document.getElementById("message").value,
        error_type:this.selectedErrors.join("|"),
        users:this.checkedNames
      }).then(
        response => {
          this.$router.go();
          return response
        },
        error => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();

        }
      );
        },
        onhold () {
      UserService.onholdQCTask({QCtaskid: this.QCtaskid}).then(
        response => {
          this.$router.go();
          return response
        },
        error => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();

        }
      );
        },
    }
}
    </script>
    
    
    
    
    <style scoped>
    
    
    
    @import '../assets/scss/snow.scss';
    


    .container_mod {
  width: 100% !important;
  max-width: 100% !important;
}

    .green {
        background-color: #41b883 !important;
        border: 1px solid #41b883 !important;
    }

    .orange {
        background-color: #b88e41 !important;
        border: 1px solid #b88e41 !important;
    }
    .grey {
        background-color: #6d6964 !important;
        border: 1px solid #6d6964 !important;
    }
    
    .card-container.card {
      padding: 40px 40px;
    }
    
    .additional {
      max-width: 50%;
    }
    
    .validate-image{
    }
    
    .card {
      background-color: #f7f7f7;
      padding: 20px 25px 30px;
      margin: 0 auto 25px;
      margin-top: 50px;
      -moz-border-radius: 2px;
      -webkit-border-radius: 2px;
      border-radius: 2px;
      -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
      -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    }

    
    .custom-link {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border-radius: 0.3em;
        box-sizing: border-box;
        background-color: transparent;
        font-size: .7em;
        font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
        padding: 0.75em;
        display: block;
        font-weight: 400;
        line-height: 1.2em;
        margin: 0;
        border: 1px solid #964038;
        background-color: #964038;
        color: #fff;
        min-width: 0;
        width: auto;
        font-weight: 700;
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        text-decoration: none;
    }
    
.margin-left{
        margin-left: 50px !important;
    }
    

.inputEle {
  margin: 10px;
  margin-left: 0px;
}

.labeled {
  font-weight: bold;
}

/* Dropdown CSS */
select {
  margin: 10px;
  padding: 5px 35px 5px 5px;
  font-size: 16px;
  border: 1px solid #ccc;
  height: 34px;
  border-radius: 5px;
  width: 98%;
}

select::-ms-expand {
  display: none; /* Remove default arrow in Internet Explorer 10 and 11 */
}

/* Checkbox */
.checked {
  margin: 15px;
  transform: scale(1.5);
}

/* Target Internet Explorer 9 to undo the custom arrow */
@media screen and (min-width: 0\0) {
  select {
    background: none\9;
    padding: 5px\9;
  }
}

[type=checkbox] {
  width: 1.5rem;
  height: 1.5 rem;
}



.img_size {
  max-width: 150px;
}


.scroll {
    overflow-x: auto;

}
    
    </style>
    