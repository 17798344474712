

<template>
  <div class="container">

    <div v-if="upload_data" class="card" style="width: 100%">
            <b-tabs content-class="mt-3">
                <b-tab title="Submission Overview" active>

                  <div>

                       
                         <b-container class="bv-example-row">
                           <b-row>
                             <b-col><h5 class="card-title">Upload: <b>{{ import_job.payload.client_mappings.mx }} </b></h5></b-col>
                             <b-col>
                              <b-button v-if="upload_data.finished_date" variant="success" style="float: right;"> Submitted</b-button>
                               <b-button v-else-if="upload_data.size > rows.length" variant="danger" style="float: right;">Not full</b-button>
                                <b-button v-else variant="secondary" style="float: right;">Ready for submission</b-button>
                             </b-col>
                           </b-row>
                         </b-container> 
 
                         
                         <b-container class="bv-example-row">
                           <b-row>
                             <b-col><p>Job Type: <b>{{ import_job.payload.job_type }}</b><br>
                              Request Size: <b>{{ import_job.payload.job_type }}</b><br>
                              Region: <b>{{ import_job.payload.client_mappings.region }}</b><br>
                              Wi: <b>{{ import_job.payload.client_mappings.wi }}</b><br>
                              Mx level: <b>{{ import_job.payload.client_mappings.mx_levle }}</b><br>
                              Priority: <b>{{ import_job.payload.client_mappings.priority }}</b><br>
                              Taxonomy: <b>{{ import_job.payload.client_mappings.taxonomy }}</b><br>
                              Jira link: <b>{{ import_job.payload.client_mappings.jira_link }}</b><br>
                              Business ID: <b>{{ import_job.payload.client_mappings.business_id }}</b><br>
                              Request type: <b>{{ import_job.payload.client_mappings.request_type }}</b><br>
                              Triage notes: <b>{{ import_job.payload.client_mappings.triage_notes }}</b><br> 

                              Speical internal instruction: <b>{{ upload_data.special_instructions }}</b><br> 

                              </p>
                             </b-col>
                           </b-row>
                         </b-container> 
                       
 
                     </div>


                </b-tab>

                <b-tab title="Fields Summary">
                  <div>
                  <vue-good-table
                    :columns='[{field:"field",label:"Field Name"},{field:"total",label:"Filled in values"},{field:"prop",label:"% filled in"},{field:"unique",label:"Unique count"}]'
                    :rows="summary">
                    <div slot="emptystate">
                    Data is being loaded...
                  </div>
                </vue-good-table>
                </div>
                </b-tab>

                <b-tab title="Data Review">
                  <div>
                    <vue-good-table
                        :columns="columns"
                        :rows="rows.slice(0,1000)"
                        styleClass="vgt-table condensed"
                        :pagination-options="{
                        enabled: true,
                        mode: 'records',
                        perPage: 100,
                        position: 'top',
                        perPageDropdown: [100, 200, 500],
                        dropdownAllowAll: false,
                        setCurrentPage: 1,
                        jumpFirstOrLast : true,
                        firstLabel : 'First Page',
                        lastLabel : 'Last Page',
                        nextLabel: 'next',
                        prevLabel: 'prev',
                        rowsPerPageLabel: 'Rows per page',
                        ofLabel: 'of',
                        pageLabel: 'page', // for 'pages' mode
                        allLabel: 'All',
                        infoFn: (params) => `my own page ${params.firstRecordOnPage}`, 
                      }">
                        <div slot="emptystate">
                        Data is being loaded...
                      </div>
                    </vue-good-table>
                    </div>


                </b-tab>
                <b-tab title="Actions">

                  <div class="input-group">
                    <span class="input-group-text">Submission ID</span>
                    <textarea id="instructions" class="form-control" aria-label="Add special instructions" :value="import_job.payload.job_id" ></textarea>
                  </div>
                  <br>
                
                  <div class="dropdown">
                    <button class="btn btn-secondary custom-link" type="submit"
                        @click="process(upload_id.toString())">
                  
                        Submit to Woflow
                      </button>
                  </div>

                  <br>

                  <button class="btn btn-secondary custom-link organe" type="submit"
                  @click="refuse(upload_id.toString())">
            
                  Reject Upload
                  </button>
                  <br><br>
                  <download-csv
                      v-if="rows.length > 0"
                      class="custom-link"
                      :data="rows"
                    >
                      Download Data
                    </download-csv>
                




                </b-tab>
            </b-tabs>
        </div>
    <div v-else>
      <h1>LOADING</h1>
      </div>
    </div>







</template>




<script>
import UploadService from "../services/integrations.service";

export default {
  name: "JobImport",



  created() {
    document.title = "ADMIN || INDIVILLAGE VALIDATION PLATFORM";
    UploadService.previewExportCSV(this.$route.params.id).then(
      (response) => {
        this.columns = response.data[0];
        this.rows = response.data[1];
        this.summary = response.data[2];
        this.upload_id = response.data[3];
        this.upload_data = response.data[4];
        this.import_job = response.data[5];
      },
      (error) => {
        this.content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
      }
    );
  },

  data() {
    return {
      rows : [],
      columns: [],
      summary: [],
      upload_id: '',
      upload_data: [],
      import_job: {}
    };
  },

  methods: {
    process(data) {
      UploadService.processExportJobCSV([data,{instructions:document.getElementById("instructions").value}]).then(
        (response) => {
          var link_to_push = '/admin'
          this.$router.push(link_to_push);
          return response;
        },
        (error) => {
          alert(
            error.response.data.message
          );
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },
    refuse(data) {
      UploadService.refuseExportJobCSV(data).then(
        (response) => {
          var link_to_push = '/admin'
          this.$router.push(link_to_push);
          return response;
        },
        (error) => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    }

  }
};
</script>

<style scoped>
@import "../assets/scss/snow.scss";

.card-container.card {
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  display: inline-block !important;
}

div.flex-wrapper {
  display: flex !important;
}

.organe {
  border: 1px solid #b8a541 !important;
  background-color: #b8a541 !important;
}

.custom-link {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.3em;
  box-sizing: border-box;
  background-color: transparent;
  font-size: 0.9em;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  padding: 0.75em;
  display: block;
  font-weight: 400;
  line-height: 1.2em;
  margin: 0;
  border: 1px solid #41b883;
  background-color: #41b883;
  color: #fff;
  min-width: 0;
  width: 14em;
  font-weight: 700;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
}

.margin-left {
  margin-left: 80px !important;
}
</style>
