<template>
  <div  class="container">

    <div class="card" style="width: 100%">
      <div class="card-body">
        <h5 class="card-title">QC Task Details</h5>
        <table class="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Error Type</th>
                        <th scope="col">Comments</th>
                        <th scope="col">Users who made error</th>
                        <th scope="col">User comments</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{ qctasks_data.error_type }}</td>
                        <td>{{ qctasks_data.comments }}</td>
                        <td v-if="qctasks_data.error_users"> <span v-for="users in qctasks_data.error_users" :key="users._id">{{ users }} </span> </td>
                        <td v-else> </td>
                        <td>{{ qctasks_data.resolution_comment }}</td>
                      </tr>
                    </tbody>
                  </table>  

                  <div class="input-group">
                    <span class="input-group-text">Add rejection comments</span>
                    <textarea id="rejection_notes" class="form-control" aria-label="Add rejection comments" ></textarea>
                  </div>
                  <br>

                  <button
                  class="custom-link green"
                  type="button"
                  @click="accept(qctasks_data._id)"
                  >
                    QC Team Mistake
                  </button>

                <button
                  class="custom-link organe margin-left"
                  type="button"
                  @click="reject(qctasks_data._id)"
                >
                  Wrong DPA assesment
                </button>

      </div>
    </div>



    <div class="card" style="width: 100%">
      <div class="card-body">
        <h5 class="card-title">Record Review - Original Data</h5>
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Field Name</th>
              <th scope="col">Submitted Value</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in original_data" :key="item.id">
              <td>{{ item[0] }}</td>
              <td>
                <b>{{ item[1] }}</b>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div
      class="card"
      style="width: 100%"
      v-for="item2 in tasks_data"
      :key="item2.id"
    >
      <div class="card-body">
        <h5 class="card-title">
          {{ item2.projecttasks.name }} - Sumbission Details
        </h5>
        <div>
          {{ item2.users.firstName }} {{ item2.users.lastName }} ({{
            item2.users.email
          }})
        </div>
        <div>
          Status: <b>{{ item2.status }}</b>
        </div>
        <table class="table table-striped" v-if="item2.payload">
          <thead>
            <tr>
              <th scope="col">Field Name</th>
              <th scope="col">Submitted Value</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="key in Object.keys(item2.payload)" :key="key.id">
              <td>{{ key }}</td>
              <td>
                <b>{{ item2.payload[key] }}</b>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "../services/user.service";

export default {
  name: "QCItem",
  created() {
    document.title = "QC MODULE || INDIVILLAGE VALIDATION PLATFORM";
    UserService.drillDownRecord(this.$route.params.id).then(
      (response) => {
        this.original_data = response.data[0];
        this.tasks_data = response.data[1];
        this.qctasks_data = response.data[2][0];
      },
      (error) => {
        alert(error.response.data.message);
        this.$router.push("/qc");
        this.content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
      }
    );
  },

  methods: {
    accept(data) {
      UserService.acceptQC([data,{rejection_notes:document.getElementById("rejection_notes").value}]).then(
        (response) => {
          var link_to_push = "/profile"
          this.$router.push(link_to_push);
          return response;
        },
        (error) => {
          alert(
            error.response.data.message
          );
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },
    reject(data) {
      UserService.rejectQC([data,{rejection_notes:document.getElementById("rejection_notes").value}]).then(
        (response) => {
          var link_to_push = "/profile"
          this.$router.push(link_to_push);
          return response;
        },
        (error) => {
          alert(
            error.response.data.message
          );
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    }

  },

  data() {
    return {
      original_data: [],
      tasks_data: [],
      qctasks_data: [],
    };
  },
};
</script>

<style scoped>
@import "../assets/scss/snow.scss";

.green {
  background-color: #41b883 !important;
  border: 1px solid #41b883 !important;
}

.card-container.card {
  padding: 40px 40px;
}

.additional {
  max-width: 50%;
}

.validate-image {
  max-width: 500px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.custom-link {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.3em;
  box-sizing: border-box;
  background-color: transparent;
  font-size: 0.9em;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  padding: 0.75em;
  display: block;
  font-weight: 400;
  line-height: 1.2em;
  margin: 0;
  border: 1px solid #964038;
  background-color: #964038;
  color: #fff;
  min-width: 0;
  width: auto;
  font-weight: 700;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
}

.margin-left {
  margin-left: 80px !important;
}


.organe {
  border: 1px solid #b8a541 !important;
  background-color: #b8a541 !important;
}

</style>
