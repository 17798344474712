import { render, staticRenderFns } from "./ProjectNew.vue?vue&type=template&id=ef3e2226&scoped=true"
import script from "./ProjectNew.vue?vue&type=script&lang=js"
export * from "./ProjectNew.vue?vue&type=script&lang=js"
import style0 from "./ProjectNew.vue?vue&type=style&index=0&id=ef3e2226&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ef3e2226",
  null
  
)

export default component.exports