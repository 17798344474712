<template>
  <div class="container">
  <div class="card">
    <img
      id="profile-img"
      src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
      class="profile-img-card"
    />
    <FormulateForm @submit="submitHandler" v-model="values" :schema="schema" />
  </div>
</div>
</template>

<script>
import User from "../models/user";

export default {
  name: "LoginItem",

  components: {},

  data() {
    return {
      values: {},
      schema: [
        {
          type: "email",
          name: "email",
          label: "Enter your email",
          validation: "required",
        },
        {
          type: "password",
          name: "password",
          label: "Enter a new password",
          validation: "required",
        },
        {
          type: "submit",
          label: "Login",
        },
      ],
      user: new User("", ""),
      loading: false,
      message: "",
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    document.title = "INDIVILLAGE VALIDATION PLATFORM";
    if (this.loggedIn) {
      this.$router.push("/profile");
    }
  },
  methods: {
    submitHandler(data) {
      this.loading = true;
      this.user.email = data.email;
      this.user.password = data.password;
      this.$validator.validateAll().then((isValid) => {
        /* if (!isValid) {
          this.loading = false;
          return;
        } */

        if (this.user.email && this.user.password) {
          this.$store.dispatch("auth/login", this.user).then(
            () => {
              this.$router.push("/profile");
            },
            (error) => {
              alert(error.response.data.message);
              this.loading = false;
              this.message =
                (error.response && error.response.data) ||
                error.message ||
                error.toString();
            }
          );
        } else if (!isValid) {
          this.loading = false;
          return;
        }
      });
    },
  },
};
</script>

<style scoped>
@import "../assets/scss/snow.scss";

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  max-width: 350px !important;
  padding: 40px 40px;
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
</style>
