import axios from "axios";
import authHeader from "./auth-header";

import { URL } from "../models/constants";

const API_URL = URL + "project/";

class UserService {
  getNew() {
    return axios.get(API_URL + "new", { headers: authHeader() });
  }

  createNew(data) {
    return axios.post(API_URL + "new", data, { headers: authHeader() });
  }

  load(data) {
    return axios.get(API_URL + "edit/" + data, { headers: authHeader() });
  }

  edit(data) {
    return axios.post(API_URL + "edit/" + data[0], data[1], {
      headers: authHeader(),
    });
  }

  details(data) {
    return axios.get(API_URL + "details/" + data, { headers: authHeader() });
  }

  // getAllProjects
  getAllProjects() {
    return axios.get(URL + "overview/getAllProjects", {
      headers: authHeader(),
    });
  }

  // getFilteredProjects
  getFilteredProjects(data) {
    return axios.post(URL + "overview/getFilteredProjects", data, {
      headers: authHeader(),
    });
  }

  //getUploadDetails
  getUploadDetails() {
    return axios.get(URL + "overview/getUploadDetails?id=6435293f7ede4f1fb092addd", {
      headers: authHeader(),
    });
  }

  generateDownload(data) {
    return axios.get(URL + "download/" + data, { headers: authHeader() });
  }

  hideUpload(data) {
    return axios.get(URL + "admin/hideupload/" + data, {
      headers: authHeader(),
    });
  }
}

export default new UserService();
