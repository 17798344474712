<template>
  <!-- Navbar exported to App.vue -->
  <div id="app">
    <nav class="navbar navbar-expand navbar-dark bg-dark">
      <a href class="navbar-brand" @click.prevent>
        <img src="../assets/logo.png" alt="Indivillage" class="image" />
      </a>
      <div class="navbar-nav mr-auto">
        <li v-if="showAdminBoard" class="nav-item">
          <router-link to="/admin" class="nav-link">Admin Board</router-link>
        </li>
        <li v-if="showAdminBoard" class="nav-item">
          <router-link to="/dailystats" class="nav-link"
            >Daily Stats</router-link
          >
        </li>
        <li v-if="showAdminBoard" class="nav-item">
          <router-link to="/uploadsearch" class="nav-link"
            >Upload Summary</router-link
          >
        </li>

        <li v-if="showProdBoard" class="nav-item">
          <router-link to="/inProd" class="nav-link"
            >Production Summary</router-link
          >
        </li>

        <li v-if="showProdBoard" class="nav-item">
          <router-link to="/import/jobs" class="nav-link"
            >Woflow uploads</router-link
          >
        </li>

        <li v-if="showProdBoard" class="nav-item">
          <router-link to="/import/jobscsv" class="nav-link"
            >Woflow uploads (new)</router-link
          >
        </li>

        <li v-if="showProdBoard" class="nav-item">
          <router-link to="/openTasks" class="nav-link"
            >All Open Tasks</router-link
          >
        </li>

        <!-- <li v-if="showAdminBoard" class="nav-item">
          <router-link to="/mock" class="nav-link"
            >MockUp Page</router-link
          >
        </li> -->

        <li v-if="showModeratorBoard" class="nav-item">
          <router-link to="/mod" class="nav-link">Team Management</router-link>
        </li>
        <li class="nav-item">
          <router-link v-if="showQCBoard" to="/qc" class="nav-link"
            >QC Module</router-link
          >
        </li>
        <li class="nav-item">
          <router-link v-if="currentUser" to="/user" class="nav-link"
            >Validation</router-link
          >
        </li>
      </div>

      <div v-if="!currentUser" class="navbar-nav ml-auto">
        <li class="nav-item">
          <router-link to="/register" class="nav-link">
            <font-awesome-icon icon="user-plus" />Sign Up
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="/login" class="nav-link">
            <font-awesome-icon icon="sign-in-alt" />Login
          </router-link>
        </li>
      </div>

      <div v-if="currentUser" class="navbar-nav ml-auto">
        <li class="nav-item">
          <router-link to="/profile" class="nav-link">
            <font-awesome-icon icon="user" />
            {{ currentUser.username }}
          </router-link>
        </li>
        <li class="nav-item">
          <a class="nav-link" href @click.prevent="logOut">
            <font-awesome-icon icon="sign-out-alt" />LogOut
          </a>
        </li>
      </div>
    </nav>

    <!--     <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <a class="navbar-brand" href="#">Navbar</a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item active">
            <a class="nav-link" href="#"
              >Home <span class="sr-only">(current)</span></a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Link</a>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Dropdown
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" href="#">Action</a>
              <a class="dropdown-item" href="#">Another action</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#">Something else here</a>
            </div>
          </li>
          <li class="nav-item">
            <a class="nav-link disabled" href="#">Disabled</a>
          </li>
        </ul>
        <form class="form-inline my-2 my-lg-0">
          <input
            class="form-control mr-sm-2"
            type="search"
            placeholder="Search"
            aria-label="Search"
          />
          <button class="btn btn-outline-success my-2 my-sm-0" type="submit">
            Search
          </button>
        </form>
      </div>
    </nav> -->
  </div>
</template>

<script>
export default {
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes("ROLE_ADMIN");
      }

      return false;
    },
    showModeratorBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes("ROLE_MODERATOR");
      }
      return false;
    },
    showQCBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes("ROLE_QC");
      }
      return false;
    },
    showProdBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes("ROLE_PROD_HEAD");
      }
      return false;
    },
    showDev() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes("ROLE_DEV");
      }
      return false;
    },

  },
  methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped>
.image {
  width: 50%;
  margin-left: 30px;
}

/* Media Query for Mobile Devices */
@media (max-width: 480px) {
  nav {
    width: 750px;
  }
}
</style>
