<template>
  <div class="container">
    <div class="row">
      <div class="col-sm additional">
        <div class="card card-container">
          <div style="display: flex">
            <div
              v-for="image in imported_images"
              :key="image.id"
              style="display: grid"
            >
              {{ image[1] }}:
              <img :src="image[0]" class="img-thumbnail validate-image" style="background-color: #FFFFFF" />
            </div>
          </div>
          <div v-for="item in imported_data" :key="item.id">
            {{ item[0] }} :

            <span v-if="links.includes(item[1])">
              <a :href="item[1]" target="_blank">{{ item[1] }}</a></span
            >
            <span v-else>
              <b>{{ item[1] }}</b></span
            >
          </div>
          <br />

          <h5 v-if="reccs.length>0">Reccomendations:</h5>
          <ul v-if="reccs.length>0">
          <li v-for="rec in reccs" :key="rec.id">
            {{ rec }}
          </li>
        </ul>




          <div v-if="show">
            <br /><br />
            <h5>Search database for title</h5>
            <input type="text" name="search-box" @keyup="search" />
            <template v-if="!state.noSearch && state.searchResults.length > 0">
              <div
                class="search-item"
                v-for="(result, index) in state.searchResults"
                v-bind:key="index"
              >
                <hr v-if="index > 0" />
                <a
                  href="#"
                  v-on:click.prevent="suggestionTitleSelected(result)"
                  class="search-results"
                  >{{ result }}</a
                >
              </div>
            </template>
            <template v-else-if="!state.noSearch">
              <div class="search-item">
                <p>Sorry. No Results.</p>
              </div>
            </template>
          </div>

          <div v-if="showBrand">
            <br /><br />
            <h5>Search database for Brand Info</h5>
            <input type="text" name="search-box" @keyup="searchBrand" />
            <template
              v-if="!state.noBrandSearch && state.searchResultsBrand.length > 0"
            >
              <div
                class="search-item"
                v-for="(Brand, Brandindex) in state.searchResultsBrand"
                v-bind:key="Brandindex"
              >
                <hr v-if="Brandindex > 0" />
                <a
                  href="#"
                  v-on:click.prevent="suggestionBrandSelected(Brand.name)"
                  class="search-results"
                >
                  {{ Brand.name }}
                </a>
              </div>
            </template>
            <template v-else-if="!state.noSearch">
              <div class="search-item">
                <p>Sorry. No Results.</p>
              </div>
            </template>
          </div>


          <div v-if="showBrandCH">
            <br /><br />
            <h5>Search database for Brand Info</h5>
            <input type="text" name="search-box" @keyup="searchBrandCH" />
            <template
              v-if="!state.noBrandSearchCH && state.searchResultsBrandCH.length > 0"
            >
              <div
                class="search-item"
                v-for="(BrandCH, BrandindexCH) in state.searchResultsBrandCH"
                v-bind:key="BrandindexCH"
              >
                <hr v-if="BrandindexCH > 0" />
                <a
                  href="#"
                  v-on:click.prevent="suggestionBrandSelectedCH(BrandCH)"
                  class="search-results"
                >
                  {{ BrandCH.l1_name }} : {{ BrandCH.l2_name }}
                </a>
              </div>
            </template>
            <template v-else-if="!state.noSearchCH">
              <div class="search-item">
                <p>Sorry. No Results.</p>
              </div>
            </template>
          </div>



          <div v-if="showPLU">
            <br /><br />
            <h5>Search database for PLU Info</h5>
            <input type="text" name="search-box" @keyup="searchPLU" />
            <template
              v-if="!state.noPLUSearch && state.searchResultsPLU.length > 0"
            >
              <div
                class="search-item"
                v-for="(PLU, PLUindex) in state.searchResultsPLU"
                v-bind:key="PLUindex"
              >
                <hr v-if="PLUindex > 0" />
                <a
                  href="#"
                  v-on:click.prevent="suggestionPLUSelected(PLU)"
                  class="search-results"
                >
                  {{ PLU.sheet }} => {{ PLU.item_name }} => Photo_ID:
                  {{ PLU.photo_id }}; QTY: {{ PLU.qty }}; UNT: {{ PLU.unit }}</a
                >
              </div>
            </template>
            <template v-else-if="!state.noSearch">
              <div class="search-item">
                <p>Sorry. No Results.</p>
              </div>
            </template>
          </div>

          <div v-if="showPLU">
            <br /><br />
            <h5>Search database for Alcohol PLU Info</h5>
            <input type="text" name="search-box" @keyup="searchAlcohol" />
            <template
              v-if="!state.noAlcoholSearch && state.searchResultsAlcohol.length > 0"
            >
              <div
                class="search-item"
                v-for="(Alcohol, Alcoholindex) in state.searchResultsAlcohol"
                v-bind:key="Alcoholindex"
              >
                <hr v-if="Alcoholindex > 0" />
                <a
                  href="#"
                  v-on:click.prevent="suggestionPLUSelected(Alcohol)"
                  class="search-results"
                >
                  {{ Alcohol.item_name }} => Photo_ID:
                  {{ Alcohol.photo_id }}; QTY: {{ Alcohol.qty }}; UNT: {{ Alcohol.unit }}</a
                >
              </div>
            </template>
            <template v-else-if="!state.noSearch">
              <div class="search-item">
                <p>Sorry. No Results.</p>
              </div>
            </template>
          </div>

          <div v-if="showPLU">
            <br /><br />
            <h5>Search database for Shutterstock images Info</h5>
            <input type="text" name="search-box" @keyup="searchPLUStock" />
            <template
              v-if="!state.noPLUStockSearch && state.searchResultsPLUStock.length > 0"
            >
              <div
                class="search-item"
                v-for="(PLUStock, PLUindexStock) in state.searchResultsPLUStock"
                v-bind:key="PLUindexStock"
              >
                <hr v-if="PLUindexStock > 0" />
                <a
                  href="#"
                  v-on:click.prevent="suggestionPLUSelected(PLUStock)"
                  class="search-results"
                >
                  {{ PLUStock.item_name }} => Photo_ID:
                  {{ PLUStock.photo_id }}; QTY: {{ PLUStock.qty }}; UNT: {{ PLUStock.unit }}</a
                >
              </div>
            </template>
            <template v-else-if="!state.noSearch">
              <div class="search-item">
                <p>Sorry. No Results.</p>
              </div>
            </template>
          </div>



        </div>
      </div>
      <div class="col-sm additional">
        <div class="card card-container">
          <h3>Time Left: {{ countDown }} seconds</h3>
          <FormulateForm
            @submit="submitHandler"
            v-model="values"
            :schema="schema"
          />
          <h5>Other Actions</h5>
          <div>
            <button
              v-if="showL3"
              class="custom-link"
              type="button"
              @click="passToExpert"
            >
              Pass To Expert
            </button>

            <button
              v-if="showL1"
              class="custom-link margin-left"
              type="button"
              @click="rejectTask"
            >
              Reject Task
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "../services/user.service";
import { reactive } from "vue";

export default {
  name: "UserItem",
  setup() {
    const state = reactive({
      searchResults: [],
      searchResultsPLU: [],
      searchResultsAlcohol: [],
      searchResultsPLUStock: [],
      searchResultsBrand: [],
      searchResultsBrandCH: [],
      noSearch: true,
      noPLUSearch: true,
      noAlcoholSearch: true,
      noPLUStockSearch: true,
      noBrandSearch: true,
      noBrandSearchCH: true,
    });
    function search(e) {
      let search = e.target.value.trim();
      UserService.searchItemName({ search: search })
        .then((res) => {
          if (search === "") {
            state.noSearch = true;
            return;
          }
          state.noSearch = false;
          state.searchResults = res.data.map((title) => title.name);
        })
        .catch((err) => {
          throw new Error(err);
        });
    }
    function searchPLU(e) {
      let searchPLU = e.target.value.trim();
      UserService.searchPLU({ search: searchPLU })
        .then((res) => {
          if (search === "") {
            state.noPLUSearch = true;
            return;
          }
          state.noPLUSearch = false;
          state.searchResultsPLU = res.data;
        })
        .catch((err) => {
          throw new Error(err);
        });
    }
    function searchAlcohol(e) {
      let searchAlcohol = e.target.value.trim();
      UserService.searchAlcohol({ search: searchAlcohol })
        .then((res) => {
          if (search === "") {
            state.noAlcoholSearch = true;
            return;
          }
          state.noAlcoholSearch = false;
          state.searchResultsAlcohol = res.data;
        })
        .catch((err) => {
          throw new Error(err);
        });
    }
    function searchPLUStock(e) {
      let searchPLUstock = e.target.value.trim();
      UserService.searchPLUStock({ search: searchPLUstock })
        .then((res) => {
          if (search === "") {
            state.noPLUStockSearch = true;
            return;
          }
          state.noPLUStockSearch = false;
          state.searchResultsPLUStock = res.data;
        })
        .catch((err) => {
          throw new Error(err);
        });
    }
    function searchBrand(e) {
      let searchBrand = e.target.value.trim();
      UserService.searchBrand({ search: searchBrand })
        .then((res) => {
          if (search === "") {
            state.noBrandSearch = true;
            return;
          }
          state.noBrandSearch = false;
          state.searchResultsBrand = res.data;
        })
        .catch((err) => {
          throw new Error(err);
        });
    }

    function searchBrandCH(e) {
      let searchBrandCH = e.target.value.trim();
      UserService.searchBrandCH({ search: searchBrandCH })
        .then((res) => {
          if (search === "") {
            state.noBrandSearchCH = true;
            return;
          }
          state.noBrandSearchCH = false;
          state.searchResultsBrandCH = res.data;
        })
        .catch((err) => {
          throw new Error(err);
        });
    }


    return { search, searchPLU,searchAlcohol,searchBrand,searchPLUStock,searchBrandCH, state };
  },

  created() {
    document.title = "VALIDATION || INDIVILLAGE VALIDATION PLATFORM";
    UserService.getTask(this.$route.params.id).then(
      (response) => {
        this.imported_images = response.data[0];
        this.imported_data = response.data[1];
        this.schema = response.data[2];
        this.show = response.data[3];
        this.links = response.data[4];
        this.showL1 = response.data[5];
        this.showPLU = response.data[6];
        this.positions = response.data[7];
        this.showL3 = response.data[8];
        this.showBrand = response.data[9];
        this.reccs = response.data[10];
        this.showBrandCH = response.data[11];
      },
      (error) => {
        alert(error.response.data.message);
        this.$router.push("/user");
        this.content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
      }
    );
    this.countDownTimer();
  },

  data() {
    return {
      values: {},
      imported_images: [],
      imported_data: [],
      schema: [],
      show: false,
      showL1: false,
      showL3: false,
      links: [],
      showPLU: false,
      showBrand: false,
      showBrandCH: false,
      positions: {},
      reccs: [],
      countDown: 300,
    };
  },
  methods: {
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      } else {
        this.$router.push("/user");
      }
    },
    submitHandler(data) {
      UserService.completeTask(data).then(
        (response) => {
          this.$router.go();
          return response;
        },
        (error) => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },
    rejectTask() {
      UserService.rejectTask(this.schema[1].value).then(
        (response) => {
          this.$router.go();
          return response;
        },
        (error) => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },
    passToExpert() {
      UserService.passToExpertTask(this.schema[1].value).then(
        (response) => {
          this.$router.go();
          return response;
        },
        (error) => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },
    suggestionTitleSelected(i) {
      this.values["Woflow Cleaned Item Name"] = i;
    },
    suggestionPLUSelected(i) {
      if (!(this.positions["plu_photo_id"] == null)) {
        this.values["PLU Photo ID"] = i.primary_photo_id;
      }
      if (!(this.positions["qty"] == null)) {
        this.values["approximate_sold_as_quantity"] = i.approximate_sold_as_quantity;
      }
      if (!(this.positions["unit"] == null)) {
        this.values["approximate_sold_as_unit"] = i.approximate_sold_as_unit;
      }

      if (!(this.positions["primary_photo_url"] == null)) {
        this.values["primary_photo_url"] = i.primary_photo_url;
      }

      if (!(this.positions["non_standard"] == null)) {
        this.values["Non Standard Photo"] = i.Non_Standard_Photo;
      }

      if (!(this.positions["photo_source"] == null)) {
        this.values["Photo Source"] = i.Photo_Source;
      }

      if (i.primary_photo_url) {
        this.imported_images.push([i.primary_photo_url,'primary_photo_url']);
      }

    },
    suggestionBrandSelected(i) {
      if (!(this.positions["brand_name"] == null)) {
        this.values["Brand Name"] = i;
      } else if (!(this.positions["brand_path"] == null)) {
        this.values["Woflow brand_path"] = i;
      } else if (!(this.positions["vx_brand_path"] == null)) {
        this.values["Vx brand_path"] = i;
      } else if (!(this.positions["correct_brand_path"] == null)) {
        this.values["Correct Brand Path"] = i;
      }
    },

    suggestionBrandSelectedCH(i) {
      if (!(this.positions["Correct Brand"] == null)) {
        this.values["Correct Brand"] = i.search_name;}
      if (!(this.positions["Matched Path ID"] == null)) {
        this.values["Matched Path ID"] = i.path_id;
      }
    },
  },
};
</script>

<style scoped>
@import "../assets/scss/snow.scss";

.search-results {
  font-size: 15px;
  color: #41b883;
}

.card-container.card {
  padding: 40px 40px;
}

.additional {
  max-width: 50%;
}

.validate-image {
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

div.flex-wrapper {
  display: flex !important;
}

.custom-link {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.3em;
  box-sizing: border-box;
  background-color: transparent;
  font-size: 0.9em;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  padding: 0.75em;
  display: block;
  font-weight: 400;
  line-height: 1.2em;
  margin: 0;
  border: 1px solid #964038;
  background-color: #964038;
  color: #fff;
  min-width: 0;
  width: auto;
  font-weight: 700;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
}

.margin-left {
  margin-left: 80px !important;
}

input[name="search-box"] {
  display: block;
  margin: 2rem auto;
  max-width: 500px;
  width: 95%;
  padding: 0.5rem 1rem;
}
.search-item {
  margin: 2rem auto;
  max-width: 500px;
  width: 95%;
  font-weight: 600;
  font-size: 1.25rem;
}
.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}
</style>
