import axios from 'axios';
import authHeader from './auth-header';

import {URL} from '../models/constants';

const API_URL = URL+'integrations/';

class UserService {


  getImportJobsCSV() {
    return axios.get(API_URL+'getimportjobsCSV', { headers: authHeader() });

  }

  previewImportCSV(data) {
    return axios.get(API_URL+'importGS/'+data, { headers: authHeader() });

  }

  addToProd(data) {
    return axios.post(API_URL+'addtoProdGS/'+data[0][0]+'/'+data[0][1],data[1], { headers: authHeader() });

  }

  manualAddToProd(data) {
    return axios.get(API_URL+'ManualAddtoProdGS/'+data, { headers: authHeader() });

  }


  manualCompleteAddToProd(data) {
    return axios.get(API_URL+'manualCompleteAddToProdGS/'+data, { headers: authHeader() });

  }

  refuseAddToProd(data) {
    return axios.post(API_URL+'RefuseAddtoProdGS/'+data[0],data[1], { headers: authHeader() });

  }


  

  processImportJobCSV(data) {
    return axios.post(API_URL+'imports3csv/'+data[0],data[1], { headers: authHeader() });

  }


  refuseImportJobCSV(data) {
    return axios.post(API_URL+'imports3csv_reject/'+data[0],data[1], { headers: authHeader() });

  }


  previewExportCSV(data) {
    return axios.get(API_URL+'previewsubmission/'+data, { headers: authHeader() });

  }

  processExportJobCSV(data) {
    return axios.post(API_URL+'submitsubmission/'+data[0], data[1], { headers: authHeader() });

  }


  refuseExportJobCSV(data) {
    return axios.get(API_URL+'rejectsubmission/'+data, { headers: authHeader() });

  }

  

}

export default new UserService();
