import axios from 'axios';
import authHeader from './auth-header';

import {URL} from '../models/constants';

const API_URL = URL;

class UserService {

  allMyUsers() {
    return axios.get(API_URL + 'myteam', { headers: authHeader() });
  }

  loadMod(data) {
    return axios.get(API_URL + 'user/mod/' + data, { headers: authHeader() });
  }

  editMod(data) {
    return axios.post(API_URL + 'user/mod/' + data[0], data[1], { headers: authHeader() });
  }

  loadModPerformance(data) {
    return axios.get(API_URL + 'myteam/stats/' + data, { headers: authHeader() });
  }




}

export default new UserService();
