import axios from 'axios';
import authHeader from './auth-header';

import {URL} from '../models/constants';

const API_URL = URL+'team/';

class UserService {


  getNew() {
    return axios.get(API_URL + 'new', { headers: authHeader() });
  }

  createNew(data) {
    return axios.post(API_URL + 'new', data, { headers: authHeader() });

  }

  load(data) {
    return axios.get(API_URL + 'edit/' + data, { headers: authHeader() });
  }

  edit(data) {
    return axios.post(API_URL + 'edit/' + data[0], data[1], { headers: authHeader() });

  }


}

export default new UserService();
