<template>
  <div class="container">
    <div class="card card-container">
      <img
        id="profile-img"
        src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
        class="profile-img-card"
      />
      <FormulateForm
        @submit="submitHandler"
        v-model="values"
        :schema="schema"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { URL } from "../models/constants.js";

export default {
  async created() {
    document.title = "INDIVILLAGE VALIDATION PLATFORM";
    /* const teams = await axios.get(
      "http://southvalidationapp-env.eba-jmaq2kez.ap-south-1.elasticbeanstalk.com/api/general/teams"
    ); */
    const teams = await axios.get(URL + "general/teams/");
    if (teams.data) {
      this.schema = teams.data;
    }
  },

  name: "TestItem",
  components: {},
  data() {
    return {
      values: {},
      schema: null,
    };
  },

  /* Computed Properties */
  computed: {},

  /* METHODS */
  methods: {
    setSomeErrors() {
      // do some processing...
      const errors = {
        fieldErrors: { username: "Sorry, no such username exists!" },
        formErrors: ["Incorrect login, please try again."],
      };
      this.$formulate.handle(errors, "login");
    },
    reset() {
      this.$formulate.reset("login");
    },
    submitHandler(data) {
      axios
        /* .post(
          "http://southvalidationapp-env.eba-jmaq2kez.ap-south-1.elasticbeanstalk.com/api/auth/signup",
          data
        ) */
        .post(URL + "auth/signup", data)
        .then(
          (response) => {
            var link_to_push = "/login";
            this.$router.push(link_to_push);
            return response;
          },
          (error) => {
            alert(error.response.data.message);
            this.content =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
          }
        );
        console.log(data);
    },
  },
};
</script>

<style scoped>
@import "../assets/scss/snow.scss";

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
</style>
